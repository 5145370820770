import { useEffect, type MutableRefObject } from 'react';

export function useOutsideClick(
    elementRef: MutableRefObject<HTMLElement | null>,
    callback: () => void
): void {
    useEffect(() => {
        const handleDocumentClick = (e: MouseEvent): void => {
            if (!elementRef.current?.contains(e.target as Node)) {
                callback();
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [elementRef, callback]);
}
