/**
 * @generated SignedSource<<d7cd4530784c34da96caa60c0573baf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CartNav_financialAmount$data = {
  readonly amount: number | null;
  readonly convertedAmountList: ReadonlyArray<{
    readonly amount: number | null;
    readonly currency: Currency | null;
  } | null> | null;
  readonly currency: Currency | null;
  readonly " $fragmentType": "CartNav_financialAmount";
};
export type CartNav_financialAmount$key = {
  readonly " $data"?: CartNav_financialAmount$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartNav_financialAmount">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartNav_financialAmount",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmountList",
      "kind": "LinkedField",
      "name": "convertedAmountList",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "FinancialAmount",
  "abstractKey": null
};
})();

(node as any).hash = "0acaf8d56c1e760cd755053b99d56c54";

export default node;
