import {
    type FC,
    type SyntheticEvent,
    type ReactNode,
    createRef,
    useState,
    useEffect,
} from 'react';
import classnames from 'classnames';

import { __INTERNAL_ONLY_HAS_ENOUGH_PARENT_HEIGHT__ } from './helpers';
import { SIZES } from '../constants';

import styles from './AnimatedPlaceholderContainer.scss';

type DownshiftCallback = () => void;

type marginSizes = typeof SIZES.none | typeof SIZES.small | typeof SIZES.medium;

type AnimatedPlaceholderContainerProps = {
    children: (arg0: { placeholderDidRender: boolean }) => ReactNode;
    marginLeft?: marginSizes;
    marginRight?: marginSizes;
    placeholderPaddingLeft?: marginSizes;
    onClick?: (e: SyntheticEvent<HTMLDivElement> | DownshiftCallback) => void;
    placeholder?: string;
    showAnimatedPlaceholder: boolean;
};

const ANIMATION_DELAY = 10;
const ANIMATION_DURATION = 100;

export const AnimatedPlaceholderContainer: FC<AnimatedPlaceholderContainerProps> = props => {
    const {
        children,
        marginLeft,
        marginRight,
        placeholderPaddingLeft,
        onClick,
        placeholder,
        showAnimatedPlaceholder,
    } = props;

    const containerNode = createRef<HTMLDivElement>();

    const startAnimatedUp = !!placeholder && showAnimatedPlaceholder;

    const [shouldRender, setShouldRender] = useState(false);
    const [animatedPlaceholderUp, setAnimatedPlaceholderUp] = useState(startAnimatedUp);

    function updateRenders(): void {
        const shouldRenderPlaceholder: boolean =
            !!placeholder &&
            showAnimatedPlaceholder &&
            __INTERNAL_ONLY_HAS_ENOUGH_PARENT_HEIGHT__(containerNode);
        // Did render change
        if (shouldRenderPlaceholder !== shouldRender) {
            // Will Render
            if (shouldRenderPlaceholder) {
                if (!animatedPlaceholderUp) {
                    // Animate Up
                    setShouldRender(true);
                    setTimeout(() => setAnimatedPlaceholderUp(true), ANIMATION_DELAY);
                } else {
                    // Render only
                    setShouldRender(true);
                }
                // Animate Out
            } else if (animatedPlaceholderUp) {
                setAnimatedPlaceholderUp(false);
                setTimeout(() => setShouldRender(false), ANIMATION_DURATION);
            }
        }
    }

    const containerClassName = classnames(styles.animatedPlaceholderContainer, {
        [styles.marginLeftMedium]: marginLeft === SIZES.medium,
        [styles.marginLeftSmall]: marginLeft === SIZES.small,
        [styles.marginRightMedium]: marginRight === SIZES.medium,
        [styles.marginRightSmall]: marginRight === SIZES.small,
    });

    const placeholderClassName = classnames(styles.animatedPlaceholderStart, {
        [styles.animatedPlaceholderEnd]: animatedPlaceholderUp,
        [styles.placeholderPaddingLeftMedium]: placeholderPaddingLeft === SIZES.medium,
        [styles.placeholderPaddingLeftSmall]: placeholderPaddingLeft === SIZES.small,
    });

    useEffect(() => {
        updateRenders();
    });

    return (
        // this disables needing a `role` and `tabIndex` property
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            ref={containerNode}
            onClick={onClick}
            onKeyPress={onClick}
            className={containerClassName}
        >
            {shouldRender && (
                <span className={placeholderClassName} data-tn="animated-placeholder">
                    {placeholder}
                </span>
            )}
            {children({ placeholderDidRender: shouldRender })}
        </div>
    );
};
