/**
 * @generated SignedSource<<28d1c7c597a36eb27fca2a037dcf190e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAddItemToCart_item$data = {
  readonly defaultSkuId: string;
  readonly serviceId: string | null;
  readonly " $fragmentType": "useAddItemToCart_item";
} | null;
export type useAddItemToCart_item$key = {
  readonly " $data"?: useAddItemToCart_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAddItemToCart_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAddItemToCart_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultSkuId",
        "storageKey": null
      },
      "action": "LOG"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "0c32095588d9dcc8c651f66e005b8b91";

export default node;
