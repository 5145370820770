import { localStorage } from 'dibs-browser-storage';
import { actionTypes } from './constants';

export type CartItemType = 'SAVE_FOR_LATER' | 'CHECKOUT';
export type CartItem = { id: string; skuId?: string; quantity?: number; type?: CartItemType };
export type SkuCartItem = { id: string; skuId: string; quantity: number; type: CartItemType };

const CART_ID = 'cart';
export const MAX_CART_ITEMS = 20;

function getLocalStorageItems(): CartItem[] {
    return (localStorage.getItem(CART_ID) as CartItem[]) || [];
}

function getLocalStorageSkuItems(): SkuCartItem[] {
    return (localStorage.getItem(CART_ID) as SkuCartItem[]) || [];
}

/**
 * MW-541 - Bug caused local storage item IDs to be wrapped in an array.
 * This causes an error so fix any bad local storage items
 */
export function fixLocalStorageItems(items: CartItem[], shouldUseMultiCart: boolean): CartItem[] {
    return items
        .map(item => {
            if (Array.isArray(item.id)) {
                item.id = item.id[0];
            }
            //Add additional data if missing one
            //Applicable for new cart only
            if (!item?.quantity && shouldUseMultiCart) {
                item.quantity = 1;
            }
            if (!item.type && shouldUseMultiCart) {
                item.type = 'SAVE_FOR_LATER';
            }
            if (!item.skuId && shouldUseMultiCart) {
                item.skuId = `${item.id}S1`;
            }

            if (!shouldUseMultiCart && (item.type || item.quantity)) {
                item = { id: item.id };
            }

            return item;
        })
        .slice(-MAX_CART_ITEMS);
}

/**
 * The desktop stores each cart item as an object, with an id key containing the item id.
 */
function buildLocalStorageItems(current: CartItem[], next: string): { id: string }[] {
    return current
        .map(({ id }) => id)
        .filter(id => !next.includes(id))
        .concat(next)
        .map(id => ({ id }))
        .slice(-MAX_CART_ITEMS);
}

function buildLocalStorageSkuItems(item: SkuCartItem): CartItem[] {
    const currentCart = getLocalStorageItems();
    const existingPositionIndex = currentCart.findIndex(
        localItem => localItem.skuId === item?.skuId
    );
    const listIndexAddTo =
        existingPositionIndex >= 0 ? existingPositionIndex : currentCart?.length || 0;
    return [
        ...currentCart.slice(0, listIndexAddTo),
        item,
        ...currentCart.slice(listIndexAddTo + 1, currentCart.length),
    ].slice(-MAX_CART_ITEMS);
}

export function loadLocalCart(shouldUseMultiCart: boolean): {
    type: typeof actionTypes.SET_CART_ITEMS;
    payload: { items: CartItem[] };
} {
    const currentCart = fixLocalStorageItems(getLocalStorageItems(), shouldUseMultiCart);
    localStorage.setItem(CART_ID, currentCart);
    // will be ignored if componentShouldUpdate is on
    // if we remove that, then the type needs to change to be handled by reducer appropriately
    return { type: actionTypes.SET_CART_ITEMS, payload: { items: currentCart } };
}

export function removeLocalCart(): {
    type: typeof actionTypes.SET_CART_ITEMS;
    payload: { items: [] };
} {
    localStorage.deleteItem(CART_ID);

    return { type: actionTypes.SET_CART_ITEMS, payload: { items: [] } };
}

const addLocalStorageCartSkuItem = (item: SkuCartItem): CartItem[] => {
    const cartItems = buildLocalStorageSkuItems(item);
    localStorage.setItem(CART_ID, cartItems);

    return cartItems;
};

const addLocalStorageCartItem = (itemId: string): CartItem[] => {
    const currentCart = getLocalStorageItems();
    const cartItems = buildLocalStorageItems(currentCart, itemId);
    localStorage.setItem(CART_ID, cartItems);

    return cartItems;
};

const getExistingLocalCartItem = (skuId: string): SkuCartItem | null => {
    const currentCart = getLocalStorageSkuItems();
    return currentCart.find(item => item.skuId === skuId) || null;
};

export function setCartItems(itemId: string): {
    type: typeof actionTypes.SET_CART_ITEMS;
    payload: { items: CartItem[] };
} {
    const cartItems = addLocalStorageCartItem(itemId);
    return { type: actionTypes.SET_CART_ITEMS, payload: { items: cartItems } };
}

export function setMultiCartItem(item: SkuCartItem): {
    type: typeof actionTypes.SET_CART_ITEMS;
    payload: { items: CartItem[] };
} {
    const cartItems = buildLocalStorageSkuItems(item);
    localStorage.setItem(CART_ID, cartItems);

    return { type: actionTypes.SET_CART_ITEMS, payload: { items: cartItems } };
}

export function removeLocalCartItem(itemId: string): {
    type: typeof actionTypes.SET_CART_ITEMS;
    payload: { items: CartItem[] };
} {
    const currentCart = getLocalStorageItems();
    const updatedCart = currentCart.filter(item => item.id !== itemId);
    localStorage.setItem(CART_ID, updatedCart);
    return { type: actionTypes.SET_CART_ITEMS, payload: { items: updatedCart } };
}

export function removeLocalCartItemBySkuId(skuId: string): {
    type: typeof actionTypes.SET_CART_ITEMS;
    payload: { items: CartItem[] };
} {
    const currentCart = getLocalStorageItems();
    const updatedCart = currentCart.filter(item => item.skuId !== skuId);
    localStorage.setItem(CART_ID, updatedCart);
    return { type: actionTypes.SET_CART_ITEMS, payload: { items: updatedCart } };
}

export function updateLocalCartItemQuantity({
    skuId,
    quantity,
}: {
    skuId: string;
    quantity: number;
}): {
    type: typeof actionTypes.SET_CART_ITEMS;
    payload: { items: CartItem[] };
} {
    const itemToUpdate = getExistingLocalCartItem(skuId);
    if (itemToUpdate) {
        const cartItems = addLocalStorageCartSkuItem({
            ...itemToUpdate,
            quantity,
        });
        return { type: actionTypes.SET_CART_ITEMS, payload: { items: cartItems } };
    }
    const currentCart = getLocalStorageItems();
    return { type: actionTypes.SET_CART_ITEMS, payload: { items: currentCart } };
}

export function updateLocalCartItemType({ skuId, type }: { skuId: string; type: CartItemType }): {
    type: typeof actionTypes.SET_CART_ITEMS;
    payload: { items: CartItem[] };
} {
    const itemToUpdate = getExistingLocalCartItem(skuId);
    if (itemToUpdate) {
        const cartItems = addLocalStorageCartSkuItem({
            ...itemToUpdate,
            type,
        });
        return { type: actionTypes.SET_CART_ITEMS, payload: { items: cartItems } };
    }
    const currentCart = getLocalStorageItems();
    return { type: actionTypes.SET_CART_ITEMS, payload: { items: currentCart } };
}

export function removePortfolioCartItem(itemId: string): {
    type: typeof actionTypes.REMOVE_PORTFOLIO_CART_ITEM;
    payload: { removedPortfolioCartItemId: string };
} {
    return {
        type: actionTypes.REMOVE_PORTFOLIO_CART_ITEM,
        payload: { removedPortfolioCartItemId: itemId },
    };
}

export function setCartRefetchTrigger(): {
    type: typeof actionTypes.SET_CART_REFETCH_TRIGGER;
} {
    return { type: actionTypes.SET_CART_REFETCH_TRIGGER };
}
