/**
 * @generated SignedSource<<83e7df4665940a4a8fb417f4f62d6563>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountNav_user$data = {
  readonly isVerifiedTrade: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"AccountNavContent_user" | "AccountNavTrigger_user" | "TradeAccountNavContent_user">;
  readonly " $fragmentType": "AccountNav_user";
};
export type AccountNav_user$key = {
  readonly " $data"?: AccountNav_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountNav_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasTradeUserId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountNav_user",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountNavContent_user"
        }
      ]
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountNavTrigger_user"
        }
      ]
    },
    {
      "condition": "hasTradeUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TradeAccountNavContent_user"
            }
          ]
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerifiedTrade",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6efb8e85f1f5ea6ecd3885339e1e6610";

export default node;
