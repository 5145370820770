import { commitMutation } from 'react-relay';

import { type Environment } from 'react-relay';
import {
    type useAddItemToCart_userCartItemAddMutation$data,
    type useAddItemToCart_userCartItemAddMutation,
} from '../../hooks/__generated__/useAddItemToCart_userCartItemAddMutation.graphql';

import { userCartItemAddMutation } from '../../hooks/useAddItemToCart';

import { MAX_CART_ITEMS, type CartItemType, type SkuCartItem } from '../../actions/cartActions';

function addMultiCartItemsMutation({
    environment,
    input,
    onCompleted,
    onError,
}: {
    environment: Environment;
    input: {
        skuId: string;
        itemId: string;
        quantity: number;
        type: 'CHECKOUT' | 'SAVE_FOR_LATER';
    };
    onCompleted: () => void;
    onError: (error: Error) => void;
}): Promise<useAddItemToCart_userCartItemAddMutation$data> {
    return new Promise(() =>
        commitMutation<useAddItemToCart_userCartItemAddMutation>(environment, {
            mutation: userCartItemAddMutation,
            variables: {
                input,
            },
            onCompleted,
            onError,
        })
    );
}

export type FailedCartItem = {
    itemId: string;
    quantity: number;
    type: CartItemType;
    skuId: string;
};

export async function addMultiCartItems({
    items,
    environment,
    onCompleted,
    onFailedItems,
    removeLocalCart = () => {},
}: {
    items: SkuCartItem[];
    environment: Environment;
    onCompleted?: () => void;
    removeLocalCart?: () => void;
    onFailedItems?: (
        items: { itemId: string; quantity: number; type: CartItemType; skuId: string }[]
    ) => void;
}): Promise<void> {
    const failedCartItems: FailedCartItem[] = [];

    const saveItemsPromises = items.slice(-MAX_CART_ITEMS).map(item => {
        const itemInput = {
            itemId: item.id,
            quantity: item.quantity,
            type: item.type,
            skuId: item.skuId,
        };

        return new Promise(resolve => {
            addMultiCartItemsMutation({
                environment,
                input: itemInput,
                onCompleted: () => resolve(true),
                onError: () => {
                    failedCartItems.push(itemInput);
                    resolve(false);
                },
            });
        });
    });
    await Promise.all(saveItemsPromises);

    if (onCompleted) {
        onCompleted();
    }
    removeLocalCart();
    if (failedCartItems?.length && onFailedItems) {
        onFailedItems(failedCartItems);
    }
}
