import { type FC } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const { STEP_INTERACTION_GLOBAL_NAV_LOGO_CLICK } = stepInteractionConstants;
import { Link } from 'dibs-elements/exports/Link';
import Logo2020 from 'dibs-icons/exports/legacy/Logo2020';
import Logo2020Trade from 'dibs-icons/exports/legacy/Logo2020Trade';
import { LocalizedLogoVipIcon } from 'dibs-elements/exports/LocalizedLogoVipIcon';

import styles from './LogoNav-style.scss';

const label = 'home link|1stdibs logo';
const trackingObj = {
    category: 'navigation',
    action: 'header navigation click',
    label,
    eventName: EVENT_NAVIGATION,
    interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
    step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_LOGO_CLICK,
    trigger: label,
};

type Props = {
    isUserTypeTrade?: boolean;
    isUserTypeVip?: boolean;
    isStickyLogo?: boolean;
};

const Logo: FC<Props> = ({ isUserTypeTrade, isUserTypeVip, isStickyLogo }) => {
    let LogoComponent = Logo2020;
    if (isUserTypeTrade) {
        LogoComponent = Logo2020Trade;
    } else if (isUserTypeVip) {
        LogoComponent = LocalizedLogoVipIcon;
    }

    const iconWrapperClassName = classNames(styles.iconWrapper, {
        [styles.stickyLogo]: isStickyLogo,
    });

    return (
        <div className={styles.wrapper}>
            <Link
                ariaLabel="1stDibs"
                href="/"
                className={iconWrapperClassName}
                onClick={event => {
                    trackEvent(trackingObj, event);
                }}
            >
                <LogoComponent />
            </Link>
        </div>
    );
};

type PartialState = {
    header: Props;
};

const mapStateToProps = (state: PartialState): Props => {
    const { isUserTypeTrade, isUserTypeVip } = state.header;

    return {
        isUserTypeTrade,
        isUserTypeVip,
    };
};

export default connect(mapStateToProps)(Logo);
