import { type FC, type MouseEventHandler, type MouseEvent } from 'react';
import classNames from 'classnames';
import { useIntl, defineMessages } from 'dibs-react-intl';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const { STEP_INTERACTION_GLOBAL_NAV_CART_DROPDOWN_CLICK } = stepInteractionConstants;
import { Link } from 'dibs-elements/exports/Link';
import ShoppingBagIcon from 'dibs-icons/exports/legacy/ShoppingBag';

import styles from './CartNav-style.scss';

const MAX_CART_COUNT_DISPLAY = 9;

function handleOnClick(event: MouseEvent): void {
    const label = 'cart|cart header link';
    trackEvent(
        {
            category: 'navigation',
            action: 'header navigation click',
            label,
            eventName: EVENT_NAVIGATION,
            interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
            step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_CART_DROPDOWN_CLICK,
            trigger: label,
        },
        event
    );
}

type CartNavDropdownTriggerProps = {
    count: number;
    handleMouseEnter?: MouseEventHandler;
};

const CartNavCount: FC<{ count: number }> = ({ count }) => {
    const hasMaxCartItems = count === MAX_CART_COUNT_DISPLAY;
    const countClassName = classNames(styles.count, { [styles.maxCount]: hasMaxCartItems });

    return (
        <div className={styles.countWrapper}>
            <div className={countClassName}>
                {count}
                {hasMaxCartItems ? <sup>+</sup> : null}
            </div>
        </div>
    );
};

const messages = defineMessages({
    label: { id: 'dbl.Header.CartNavDropdownTrigger.label', defaultMessage: 'Cart' },
});
const CartNavDropdownTrigger: FC<CartNavDropdownTriggerProps> = ({
    count = 0,
    handleMouseEnter = () => {},
}) => {
    count = Math.min(count, MAX_CART_COUNT_DISPLAY);
    const intl = useIntl();

    return (
        <div
            data-tn="cart-nav-dropdown-trigger"
            className={styles.iconWrapper}
            onMouseEnter={handleMouseEnter}
        >
            <Link
                className={styles.icon}
                href="/my/cart/"
                ariaLabel={intl.formatMessage(messages.label)}
                onClick={handleOnClick}
            >
                {count ? <CartNavCount count={count} /> : null}
                <ShoppingBagIcon />
            </Link>
        </div>
    );
};

export default CartNavDropdownTrigger;
