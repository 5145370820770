import { type FC, type ComponentProps } from 'react';

import { dibsLazyClient } from 'dibs-lazy/exports/dibsLazyClient';
import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';

import styles from './MessageBanner-style.scss';

const MessageBanner = dibsLazyClient(
    () => import(/* webpackChunkName: "MessageBanner" */ './MessageBanner')
);

export const MessageBannerLazy: FC<ComponentProps<typeof MessageBanner>> = props => {
    return (
        <ClientSuspense fallback={<div className={styles.bannerContainer} />}>
            <MessageBanner {...props} useGlobalFallbackMessage />
        </ClientSuspense>
    );
};
