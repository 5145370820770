/**
 * @generated SignedSource<<b9fc8902ebe6dd06e962049d04186776>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CartNav_user$data = {
  readonly preferences: {
    readonly currency: Currency | null;
  } | null;
  readonly " $fragmentType": "CartNav_user";
};
export type CartNav_user$key = {
  readonly " $data"?: CartNav_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartNav_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartNav_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "07d71cd5390cadb4431b06d42a73f5b4";

export default node;
