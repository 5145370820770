/* 
    Copied from focus-trap https://github.com/davidtheclark/focus-trap
    typed and extended by Robbie. changes noted in Options type and 
    getInitialFocusNode function.
 */

/* eslint-disable no-use-before-define, @typescript-eslint/no-use-before-define */

import tabbable from 'tabbable';
export type FocusTarget = Element | string | { (): Element | null };

export interface Options {
    /**
     * A function that will be called when the focus trap activates.
     */
    onActivate?: () => void;

    /**
     * A function that will be called when the focus trap deactivates.
     */
    onDeactivate?: () => void;

    /**
     * By default, when a focus trap is activated the first element in the
     * focus trap's tab order will receive focus. With this option you can
     * specify a different element to receive that initial focus.
     */
    initialFocus?: FocusTarget;

    /**
     * By default, an error will be thrown if the focus trap contains no
     * elements in its tab order. With this option you can specify a
     * fallback element to programmatically receive focus if no other
     * tabbable elements are found. For example, you may want a popover's
     * `<div>` to receive focus if the popover's content includes no
     * tabbable elements. *Make sure the fallback element has a negative
     * `tabindex` so it can be programmatically focused.*
     */
    fallbackFocus?: FocusTarget;

    /**
     * Default: `true`. If `false`, when the trap is deactivated,
     * focus will *not* return to the element that had focus before activation.
     */
    returnFocusOnDeactivate?: boolean;

    /**
     * By default, focus trap on deactivation will return to the element
     * that was focused before activation.
     */
    setReturnFocus?: FocusTarget;

    /**
     * Default: `true`. If `false`, the `Escape` key will not trigger
     * deactivation of the focus trap. This can be useful if you want
     * to force the user to make a decision instead of allowing an easy
     * way out.
     */
    escapeDeactivates?: boolean;

    /**
     * Default: `false`. If `true`, a click outside the focus trap will
     * deactivate the focus trap and allow the click event to do its thing.
     */
    clickOutsideDeactivates?: boolean;
    /**
     * Change to focus-trap base
     *
     * Default: `true`. If `true`, the focus trap will focus on initialFocus,
     * then document.activeElement if container contains activeElement, then
     * firstTabbableElement in container, finally falling back to fallbackFocus.
     *
     * If `false`, the same order will happen but firstTabbableElement will not
     * be focused.
     *
     * Useful if an element inside container may or may not autofocus, preventing
     * use of initialFocus and fallback behavior should exclude autofocus on
     * firstTabbableElement
     */
    autoFocusFirstTabbable?: boolean;

    allowOutsideClick?: (event: MouseEvent) => boolean;
}

type ActivateOptions = Pick<Options, 'onActivate'>;
interface DeactivateOptions extends Pick<Options, 'onDeactivate'> {
    returnFocus?: boolean;
}

export type FocusTrap = {
    activate: (options?: ActivateOptions) => FocusTrap | undefined;
    deactivate: (options?: DeactivateOptions) => FocusTrap | undefined;
    pause: () => void;
    unpause: () => void;
};

let activeFocusDelay: number;

const activeFocusTraps = (function () {
    const trapQueue: FocusTrap[] = [];
    return {
        activateTrap: function (trap: FocusTrap) {
            if (trapQueue.length > 0) {
                const activeTrap = trapQueue[trapQueue.length - 1];
                if (activeTrap !== trap) {
                    activeTrap.pause();
                }
            }

            const trapIndex = trapQueue.indexOf(trap);
            if (trapIndex === -1) {
                trapQueue.push(trap);
            } else {
                // move this existing trap to the front of the queue
                trapQueue.splice(trapIndex, 1);
                trapQueue.push(trap);
            }
        },

        deactivateTrap: function (trap: FocusTrap) {
            const trapIndex = trapQueue.indexOf(trap);
            if (trapIndex !== -1) {
                trapQueue.splice(trapIndex, 1);
            }

            if (trapQueue.length > 0) {
                trapQueue[trapQueue.length - 1].unpause();
            }
        },
    };
})();

function focusTrap(element: string | Element, userOptions: Options): FocusTrap {
    const doc = document;
    const container = (
        typeof element === 'string' ? doc.querySelector(element) : element
    ) as Element;

    const config = {
        ...userOptions,
        returnFocusOnDeactivate: userOptions.returnFocusOnDeactivate ?? true,
        escapeDeactivates: userOptions.escapeDeactivates ?? true,
        autoFocusFirstTabbable: userOptions.autoFocusFirstTabbable ?? true,
    };

    const state: {
        firstTabbableNode: Element | null;
        lastTabbableNode: Element | null;
        nodeFocusedBeforeActivation: Element | null;
        mostRecentlyFocusedNode: Element | null;
        active: boolean;
        paused: boolean;
    } = {
        firstTabbableNode: null,
        lastTabbableNode: null,
        nodeFocusedBeforeActivation: null,
        mostRecentlyFocusedNode: null,
        active: false,
        paused: false,
    };

    const trap: FocusTrap = {
        activate: activate,
        deactivate: deactivate,
        pause: pause,
        unpause: unpause,
    };

    return trap;

    function activate(activateOptions?: ActivateOptions): FocusTrap | undefined {
        if (state.active) return undefined;

        updateTabbableNodes();

        state.active = true;
        state.paused = false;
        state.nodeFocusedBeforeActivation = doc.activeElement;

        const onActivate =
            activateOptions && activateOptions.onActivate
                ? activateOptions.onActivate
                : config.onActivate;
        if (onActivate) {
            onActivate();
        }

        addListeners();
        return trap;
    }

    function deactivate(deactivateOptions?: DeactivateOptions): FocusTrap | undefined {
        if (!state.active) return undefined;

        clearTimeout(activeFocusDelay);

        removeListeners();
        state.active = false;
        state.paused = false;

        activeFocusTraps.deactivateTrap(trap);

        const onDeactivate =
            deactivateOptions && deactivateOptions.onDeactivate !== undefined
                ? deactivateOptions.onDeactivate
                : config.onDeactivate;
        if (onDeactivate) {
            onDeactivate();
        }

        const returnFocus =
            deactivateOptions && deactivateOptions.returnFocus !== undefined
                ? deactivateOptions.returnFocus
                : config.returnFocusOnDeactivate;
        if (returnFocus) {
            delay(function () {
                tryFocus(getReturnFocusNode(state.nodeFocusedBeforeActivation));
            });
        }

        return trap;
    }

    function pause(): void {
        if (state.paused || !state.active) return;
        state.paused = true;
        removeListeners();
    }

    function unpause(): void {
        if (!state.paused || !state.active) return;
        state.paused = false;
        updateTabbableNodes();
        addListeners();
    }

    function addListeners(): FocusTrap | undefined {
        if (!state.active) return undefined;

        // There can be only one listening focus trap at a time
        activeFocusTraps.activateTrap(trap);

        // Delay ensures that the focused element doesn't capture the event
        // that caused the focus trap activation.
        activeFocusDelay = delay(function () {
            tryFocus(getInitialFocusNode());
        });

        doc.addEventListener('focusin', checkFocusIn, true);
        doc.addEventListener('mousedown', checkPointerDown, {
            capture: true,
            passive: false,
        });
        doc.addEventListener('touchstart', checkPointerDown, {
            capture: true,
            passive: false,
        });
        doc.addEventListener('click', checkClick, {
            capture: true,
            passive: false,
        });
        doc.addEventListener('keydown', checkKey, {
            capture: true,
            passive: false,
        });

        return trap;
    }

    function removeListeners(): FocusTrap | undefined {
        if (!state.active) return undefined;

        doc.removeEventListener('focusin', checkFocusIn, true);
        doc.removeEventListener('mousedown', checkPointerDown, true);
        doc.removeEventListener('touchstart', checkPointerDown, true);
        doc.removeEventListener('click', checkClick, true);
        doc.removeEventListener('keydown', checkKey, true);

        return trap;
    }

    function getNodeForOption(
        optionName: 'fallbackFocus' | 'initialFocus' | 'setReturnFocus'
    ): Element | null | undefined {
        const optionValue = config[optionName];

        if (optionValue instanceof Element) {
            return optionValue;
        } else if (typeof optionValue === 'string') {
            const tempNode = doc.querySelector(optionValue);
            if (!tempNode) {
                throw new Error('`' + optionName + '` refers to no known node');
            }
            return tempNode;
        } else if (typeof optionValue === 'function') {
            const tempNode = optionValue();
            if (!tempNode) {
                throw new Error('`' + optionName + '` did not return a node');
            }
            return tempNode;
        }
        return null;
    }

    function getInitialFocusNode(): Element {
        let node;
        if (getNodeForOption('initialFocus') !== null) {
            node = getNodeForOption('initialFocus');
        } else if (container?.contains(doc.activeElement)) {
            node = doc.activeElement;
        } else {
            //  change to focus-trap base
            node =
                (config.autoFocusFirstTabbable ? state.firstTabbableNode : null) ||
                getNodeForOption('fallbackFocus');
            // end Change to focus-trap base
        }

        if (!node) {
            throw new Error('Your focus-trap needs to have at least one focusable element');
        }

        return node;
    }

    function getReturnFocusNode(previousActiveElement: Element | null): Element | null {
        const node = getNodeForOption('setReturnFocus');
        return node ? node : previousActiveElement;
    }

    // This needs to be done on mousedown and touchstart instead of click
    // so that it precedes the focus event.
    function checkPointerDown(e: MouseEvent | TouchEvent): void {
        if (e.target instanceof Node && container?.contains(e.target)) return;
        if (config.clickOutsideDeactivates) {
            deactivate({
                returnFocus: !(e.target instanceof HTMLElement && tabbable.isFocusable(e.target)),
            });
            return;
        }
        // This is needed for mobile devices.
        // (If we'll only let `click` events through,
        // then on mobile they will be blocked anyways if `touchstart` is blocked.)
        if (config.allowOutsideClick && e instanceof MouseEvent && config.allowOutsideClick(e)) {
            return;
        }
        e.preventDefault();
    }

    // In case focus escapes the trap for some strange reason, pull it back in.
    function checkFocusIn(e: FocusEvent): void {
        // In Firefox when you Tab out of an iframe the Document is briefly focused.
        if (
            (e.target instanceof Node && container?.contains(e.target)) ||
            e.target instanceof Document
        ) {
            return;
        }
        e.stopImmediatePropagation();
        tryFocus(state.mostRecentlyFocusedNode || getInitialFocusNode());
    }

    function checkKey(e: KeyboardEvent): void {
        if (config.escapeDeactivates !== false && isEscapeEvent(e)) {
            e.preventDefault();
            deactivate();
            return;
        }
        if (isTabEvent(e)) {
            checkTab(e);
            return;
        }
    }

    // Hijack Tab events on the first and last focusable nodes of the trap,
    // in order to prevent focus from escaping. If it escapes for even a
    // moment it can end up scrolling the page and causing confusion so we
    // kind of need to capture the action at the keydown phase.
    function checkTab(e: KeyboardEvent): void {
        updateTabbableNodes();
        if (e.shiftKey && e.target === state.firstTabbableNode) {
            e.preventDefault();
            tryFocus(state.lastTabbableNode);
            return;
        }
        if (!e.shiftKey && e.target === state.lastTabbableNode) {
            e.preventDefault();
            tryFocus(state.firstTabbableNode);
            return;
        }
    }

    function checkClick(e: MouseEvent): void {
        if (config.clickOutsideDeactivates) return;
        if (e.target instanceof Node && container?.contains(e.target)) return;
        if (config.allowOutsideClick && config.allowOutsideClick(e)) {
            return;
        }
        e.preventDefault();
        e.stopImmediatePropagation();
    }

    function updateTabbableNodes(): void {
        const tabbableNodes = tabbable(container);
        state.firstTabbableNode = tabbableNodes[0] || getInitialFocusNode();
        state.lastTabbableNode = tabbableNodes[tabbableNodes.length - 1] || getInitialFocusNode();
    }

    function tryFocus(node: Element | null): void {
        if (node === doc.activeElement) return;
        if (!node || !(node instanceof HTMLElement) || !node.focus) {
            tryFocus(getInitialFocusNode());
            return;
        }
        node.focus();
        state.mostRecentlyFocusedNode = node;
        if (isSelectableInput(node)) {
            node.select();
        }
    }
}

function isSelectableInput(node: Element): node is HTMLInputElement {
    return node instanceof HTMLInputElement;
}

function isEscapeEvent(e: KeyboardEvent): boolean {
    return e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27;
}

function isTabEvent(e: KeyboardEvent): boolean {
    return e.key === 'Tab' || e.keyCode === 9;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function delay(fn: Function): number {
    return setTimeout(fn, 0);
}

export default focusTrap;
