/**
 * @generated SignedSource<<5ec218ee074250350d2452df402add4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Notifications_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ActivityFeed_user" | "NotificationsIcon_user" | "ShareActivityAlert_user">;
  readonly " $fragmentType": "Notifications_user";
};
export type Notifications_user$key = {
  readonly " $data"?: Notifications_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Notifications_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isClient"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Notifications_user",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShareActivityAlert_user"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ActivityFeed_user"
            }
          ]
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NotificationsIcon_user"
            }
          ]
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "cb0412bd3c115d6883fd6251451fa4b2";

export default node;
