import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type HeartOutlinedProps = {
    className?: string;
};

const HeartOutlined: FunctionComponent<HeartOutlinedProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'heart-outlined'}
            viewBox="0 0 250 250"
        >
            <path d="M29.2 129.3C22.7 120.8 1.5 91.6.3 71.9-.9 51.8 4 35.7 15 24.1 30 8.1 51.4 6.3 59.8 6.3c1.3 0 2.2 0 2.8.1 16.2.4 39.5 7.6 58.9 39.4l3.4 5.6 3.6-5.5c16.8-25.7 37.1-39 60.3-39.5 3 0 28.9-.9 48 20.6 7.2 8.1 15.3 21.4 12.5 45.6-1.9 16.9-12.5 34.8-22.5 48.4-11.8 16.1-90.2 110.3-101.9 124L29.2 129.3zM59.8 16c-7.2 0-25.6 1.2-37.8 14.7C12.6 41.2 8.5 54 10 71.3c.7 8.9 5 18.7 13.6 32.7 6.4 10.4 12.6 18.7 13.3 19.6l88 106.4 83.8-101s11.1-13.7 18.1-25.5c6.2-10.5 12-22.5 13-31.3 1.7-15.7-1.6-29.1-10.2-38.6-17.9-19.8-40-17.3-41-17.3-25.6.3-47.2 19.7-64.2 57.5-2.3-6.2-5.9-14.6-11-22.9-13.6-22.4-31.3-34.5-51.3-34.8-.7-.1-1.5-.1-2.3-.1z" />
        </svg>
    );
};
export default HeartOutlined;
