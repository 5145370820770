import { Component, type ReactNode } from 'react';
import classnames from 'classnames';
import styles from './Dropdown-style.scss';

const ALIGN_LEFT = 'left';
const ALIGN_RIGHT = 'right';
const ALIGN_CENTER = 'center';

const SIZE_SMALL = 'small';
const SIZE_MEDIUM = 'medium';
const SIZE_LARGE = 'large';
const SIZE_EXTRA_LARGE = 'extraLarge';

const alignConstants = {
    ALIGN_LEFT,
    ALIGN_RIGHT,
    ALIGN_CENTER,
} as const;
const sizeConstants = {
    SIZE_SMALL,
    SIZE_MEDIUM,
    SIZE_LARGE,
    SIZE_EXTRA_LARGE,
} as const;

export { alignConstants, sizeConstants };

type valueof<T> = T[keyof T];

type Props = {
    children: ReactNode[];
    align?: valueof<typeof alignConstants>;
    size?: valueof<typeof sizeConstants>;
    hasDropdownHeader?: boolean;
    forceOpen?: boolean;
    className?: string;
    dropdownClassName?: string;
    bodyClassName?: string;
    onHide?: () => void;
    onShow?: () => void;
};

type State = {
    isClient: boolean;
    isVisible: boolean;
};

class Dropdown extends Component<Props, State> {
    static defaultProps = {
        align: ALIGN_LEFT,
        size: SIZE_MEDIUM,
        hasDropdownHeader: false,
        forceOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            isClient: false,
            isVisible: false,
        };

        this.dropdown = null;
        this.hide = this.hide.bind(this);
        this.show = this.show.bind(this);
        this.adjustLeftStyle = this.adjustLeftStyle.bind(this);
    }

    componentDidMount(): void {
        window.addEventListener('orientationchange', this.adjustLeftStyle);
        // Initialize alignments
        this.setState({ isClient: true }, () => {
            // eslint-disable-line react/no-did-mount-set-state
            this.adjustLeftStyle();
        });
    }

    componentDidUpdate(prevProps: Props): void {
        if (!prevProps.forceOpen && this.props.forceOpen) {
            this.show();
        } else if (prevProps.forceOpen && !this.props.forceOpen) {
            this.hide();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('orientationchange', this.adjustLeftStyle);
    }

    dropdown: HTMLElement | null;

    adjustLeftStyle(): void {
        const { align } = this.props;
        if (this.dropdown && align) {
            const { width } = this.dropdown.getBoundingClientRect();

            switch (align) {
                case ALIGN_RIGHT:
                    this.dropdown.style.right = '0';
                    // `right` alignments need no further checking / calculations, exit function
                    return;

                case ALIGN_CENTER:
                    this.dropdown.style.left = `calc(50% - ${width / 2}px)`;
                    break;

                case ALIGN_LEFT:
                    this.dropdown.style.left = '0';
                    break;
            }

            const { left } = this.dropdown.getBoundingClientRect();
            const screenWidth = document.body.getBoundingClientRect().width;

            // Check if initial position overflows window boundaries and re-position if true
            if (left + width > screenWidth) {
                const overflow = left + width - screenWidth;
                // Subtract overflow from initial position
                const halfWidth = width / 2;
                this.dropdown.style.left =
                    align === ALIGN_LEFT
                        ? `${-overflow}px`
                        : `calc(50% - ${halfWidth + overflow}px)`;
            }
        }
    }

    show(): void {
        this.setState({ isVisible: true }, () => {
            const { onShow } = this.props;
            if (onShow) {
                onShow();
            }
        });
    }

    hide(): void {
        this.setState({ isVisible: false }, () => {
            const { onHide } = this.props;
            if (onHide) {
                onHide();
            }
        });
    }

    render(): ReactNode {
        const { children, className, dropdownClassName, bodyClassName, size, hasDropdownHeader } =
            this.props;
        const { isClient, isVisible } = this.state;

        const styleSize = size ? styles[size] : '';
        const customClassName = className || '';
        const customDropdownClassName = dropdownClassName || '';
        const customBodyClassName = bodyClassName || '';

        const wrapperClasses = classnames({
            [styles.wrapper]: true,
            [styles.isVisible]: isVisible,
            [customClassName]: !!className,
        });
        const dropdownClasses = classnames({
            [styles.dropdown]: true,
            [styles.isVisible]: isVisible,
            [styles.hasDropdownHeader]: hasDropdownHeader,
            [customDropdownClassName]: !!customDropdownClassName,
        });
        const bodyWrapperClasses = classnames({
            [styles.bodyWrapper]: true,
            [styles.isVisible]: isVisible,
            [styleSize]: !!size,
            [customBodyClassName]: !!customBodyClassName,
        });

        if (!children) {
            return null;
        }

        const trigger = children[0];
        const body = children.length > 1 ? children.slice(1) : null;

        return (
            <div className={wrapperClasses} onMouseEnter={this.show} onMouseLeave={this.hide}>
                {trigger}
                {isClient ? (
                    <div className={dropdownClasses}>
                        <div
                            className={bodyWrapperClasses}
                            ref={element => {
                                this.dropdown = element;
                            }}
                        >
                            {body}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default Dropdown;
