import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type AccountOutlinedProps = {
    className?: string;
};

const AccountOutlined: FunctionComponent<AccountOutlinedProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'account-outlined'}
            viewBox="0 0 250 250"
        >
            <path d="M138.8 243.4c-37.2 0-82.6-.1-133.8-.3H.2l.3-4.8c.2-3.2 2.1-31.1 6.8-41.5.7-1.7 5.7-13.4 18.4-17.9l39.6-16 16.8-7.2c.3-.1 8.1-3.3 10.2-12.5.2-1.6.1-3.5-.2-4.1-.9-1.9-.9-1.9-3.3-4.4l-.1-.1c-.7-.8-16.2-19.2-19.8-45.8-.2-1.4-2.2-13.8-.6-28.7 2.3-21 11-36.6 24.9-45.3 1.3-.8 30.4-17.8 63.7-.2 2.6 1.3 35.2 18.5 25.1 75-.3 2.3-3.5 25.4-19.7 45-.3.4-.8.9-1.3 1.4-.9 1-2 2.2-2.7 3.3-.1.5-.4 2 .2 3.9l.2.6c.1.3 1.8 7.8 11.9 12.3l21.6 9.7 31.9 12.7c.7.3 19.3 7.6 23.2 32.2v.4l2.3 32.1h-4.8c-.5 0-40.5.2-106 .2zM9.9 234.1c121 .6 209.1.2 230.1.1l-1.6-22.3c-3.2-19.3-17.4-25-17.5-25L188.6 174l-21.7-9.8c-13.3-5.9-16.5-16.4-17-18.5-1.6-5.2-.1-9.4.1-9.9l.2-.4c1.2-2.4 3-4.3 4.3-5.7.4-.4.7-.7.9-1 15.1-18.2 17.7-40.2 17.7-40.4V88c9.2-51-19.9-65.4-20.2-65.5l-.2-.1c-28.6-15.1-53.7-.7-54.7 0-28.2 17.5-20.3 64.3-20.2 64.8v.2c3.1 23.4 16.8 40.2 17.7 41.2 2.9 3.2 3.2 3.5 4.6 6.4 1.8 3.5 1.1 8.6 1 9.6l-.1.3c-3 13.6-14.2 18.6-15.7 19.2l-16.6 7.2-39.9 16.1c-9.5 3.4-13.1 12.7-13.1 12.8l-.1.3c-2.9 6.2-4.9 23.5-5.7 33.6z" />
        </svg>
    );
};

export default AccountOutlined;
