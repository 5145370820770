/**
 * @generated SignedSource<<63385e480f2890120828004a75ac873d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsIcon_user$data = {
  readonly activePromotion: {
    readonly serviceId: string | null;
  } | null;
  readonly activityFeed: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly eventDate: string | null;
        readonly type: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "NotificationsIcon_user";
};
export type NotificationsIcon_user$key = {
  readonly " $data"?: NotificationsIcon_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsIcon_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationsIcon_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Promotion",
      "kind": "LinkedField",
      "name": "activePromotion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "elementTypes",
          "value": [
            "PENDING_FEEDBACK",
            "TRADE_REWARD",
            "TRADE_REWARD_PRORATED",
            "TRADE_REWARD_PROGRAM",
            "AUTOMATED_OFFER"
          ]
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        }
      ],
      "concreteType": "ActivityFeedConnection",
      "kind": "LinkedField",
      "name": "activityFeed",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityFeedEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ActivityFeedElement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eventDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "activityFeed(elementTypes:[\"PENDING_FEEDBACK\",\"TRADE_REWARD\",\"TRADE_REWARD_PRORATED\",\"TRADE_REWARD_PROGRAM\",\"AUTOMATED_OFFER\"],first:3)"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3988bc84846e467ed1746017663d4550";

export default node;
