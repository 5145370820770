/**
 * @generated SignedSource<<b46e11718e132743cedc677689ddd061>>
 * @relayHash 738162b409401b92f1be4e00c9dc4b89
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/504.0.0-2025-04-01T10:32:06.638Z/editUserPreferencesMutation

import { ConcreteRequest } from 'relay-runtime';
export type EditUserPreferencesInput = {
  auctionMailOptin?: boolean | null;
  clientMutationId?: string | null;
  communicationPreferredLocale?: string | null;
  customOffers?: boolean | null;
  emailConfirmed?: boolean | null;
  favoriteDealersMailList?: boolean | null;
  favoriteSearchesMailList?: boolean | null;
  orderFeedbackMailOptin?: boolean | null;
  promotionalMailList?: boolean | null;
  promotionalSmsOptin?: boolean | null;
  shareActivities?: boolean | null;
  smsOptin?: boolean | null;
  tradeMailList?: boolean | null;
  userId: string;
  weeklyDigestMailOptin?: boolean | null;
};
export type editUserPreferencesMutation$variables = {
  input: EditUserPreferencesInput;
};
export type editUserPreferencesMutation$data = {
  readonly editUserPreferences: {
    readonly emailSent: boolean | null;
    readonly user: {
      readonly preferences: {
        readonly shareActivities: boolean | null;
      } | null;
    } | null;
  } | null;
};
export type editUserPreferencesMutation = {
  response: editUserPreferencesMutation$data;
  variables: editUserPreferencesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareActivities",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailSent",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editUserPreferencesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditUserPreferencesPayload",
        "kind": "LinkedField",
        "name": "editUserPreferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editUserPreferencesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditUserPreferencesPayload",
        "kind": "LinkedField",
        "name": "editUserPreferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/504.0.0-2025-04-01T10:32:06.638Z/editUserPreferencesMutation",
    "metadata": {},
    "name": "editUserPreferencesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4803fb7360722a80222d9f546b395b33";

export default node;
