import { type FunctionComponent } from 'react';
import * as React from 'react';

import styles from './Field.scss';

type FieldProps = {
    children: React.ReactNode;
};

export const Field: FunctionComponent<FieldProps> = ({ children }) => {
    return <div className={styles.fieldContainer}>{children}</div>;
};
