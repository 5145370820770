import { useEffect } from 'react';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const {
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_SEARCH_SUGGESTION_DISPLAY,
    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_RECENT_SEARCHES_DISPLAY,
} = stepInteractionConstants;
import { type Suggestions } from './useSearchSuggestions';
import { SEARCH_BAR_TRACKING } from '../utils/sharedSearchBarConstants';

type useSearchBarTrackingArgs = {
    isVisible: boolean;
    showRecentlyViewed: boolean;
    recentSearches: NonNullable<Suggestions>;
    suggestions: Suggestions;
};
export const useSearchBarTracking = ({
    isVisible,
    showRecentlyViewed,
    recentSearches,
    suggestions,
}: useSearchBarTrackingArgs): void => {
    const hasRecentSearches = recentSearches.length > 0;
    const hasSuggestions =
        !!suggestions && suggestions.some(suggestion => (suggestion?.terms || []).length > 0);

    useEffect(() => {
        // Need to have hasSuggestions here to prevent double tracking on re-render
        if (isVisible && hasRecentSearches && showRecentlyViewed && !hasSuggestions) {
            trackEvent({
                category: SEARCH_BAR_TRACKING.CATEGORIES.NAVIGATION,
                action: SEARCH_BAR_TRACKING.ACTIONS.RECENT_SEARCHES_DISPLAY,
                eventName: EVENT_NAVIGATION,
                interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                step_interaction_name:
                    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_RECENT_SEARCHES_DISPLAY,
            });
        }
        if (isVisible && !showRecentlyViewed && hasSuggestions) {
            trackEvent({
                category: SEARCH_BAR_TRACKING.CATEGORIES.NAVIGATION,
                action: SEARCH_BAR_TRACKING.ACTIONS.SEARCH_SUGGESTIONS_DISPLAY,
                eventName: EVENT_NAVIGATION,
                interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                step_interaction_name:
                    STEP_INTERACTION_GLOBAL_NAV_SEARCH_BAR_SEARCH_SUGGESTION_DISPLAY,
            });
        }
    }, [isVisible, hasRecentSearches, showRecentlyViewed, hasSuggestions]);
};
