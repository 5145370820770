import { graphql, type Environment } from 'react-relay';

import serverVars from 'server-vars';
import { type SupportedMFATypes } from 'dibs-recaptcha/exports/recaptcha';
import { commitMutationWithRecaptcha } from 'dibs-recaptcha/exports/commitMutationWithRecaptcha';

import {
    type UserEmailLoginInput,
    type emailLoginMutation as emailLoginMutationType,
    type emailLoginMutation$data,
} from './__generated__/emailLoginMutation.graphql';

const mutation = graphql`
    mutation emailLoginMutation($input: UserEmailLoginInput!) {
        userEmailLogin(input: $input) {
            clientMutationId
        }
    }
`;

export const emailLoginMutation = async (
    environment: Environment,
    { emailLoginToken }: Partial<UserEmailLoginInput>
): Promise<emailLoginMutation$data> => {
    const mutationInputVariables = {
        cookieDomain: serverVars.get('cookieDomain'),
        emailLoginToken,
    };

    const supportedMFATypes: SupportedMFATypes = ['CODE'];
    const executeCaptchaConfig = {
        action: 'emailLogin',
        supportedMFATypes,
        featureFlag: 'loginCaptcha',
        featureFlagV3: 'loginCaptchaV3',
    };

    return commitMutationWithRecaptcha<
        Partial<UserEmailLoginInput>,
        emailLoginMutationType,
        emailLoginMutation$data
    >(environment, {
        mutation,
        mutationInputVariables,
        executeCaptchaConfig,
    });
};
