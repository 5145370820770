import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FC } from 'react';

type MaskDirectLoginProps = {
    className?: string;
};

const MaskDirectLogin: FC<MaskDirectLoginProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'mask-direct-login'}
            viewBox="0 0 250 250"
        >
            <path d="M147.4 96.2c-6.6 6.3-35.6 13.3-46.4 16.2-10.9 2.9-20.1 7.9-26 13.6-5.9-5.7-15.1-10.7-26-13.6-10.8-2.9-39.8-9.9-46.4-16.2-5.6-5.3 1.3 20.9 3 26.2 8.7 27.7 14.8 27.9 32.6 30.9 9.9 1.7 20.6 1.7 29-.2 3.7-2.2 3.3-12.6 7.8-12.6s4.1 10.5 7.8 12.6c8.4 2 19 1.9 29 .2 17.8-3.1 23.9-3.2 32.6-30.9 1.7-5.3 8.7-31.5 3-26.2zm-84.7 42.5c-1.6 6.2-12.5 8.7-24.3 5.6-11.7-3.1-20-10.7-18.3-16.9 1.6-6.2 12.5-8.7 24.3-5.6 11.7 3.1 19.9 10.6 18.3 16.9zm49.4 5.5c-11.8 3.2-22.6.6-24.3-5.6s6.6-13.8 18.3-16.9c11.8-3.1 22.6-.6 24.3 5.6 1.6 6.2-6.6 13.8-18.3 16.9z" />
            <polygon points="250.1,194.2 218.5,194.2 218.5,185.2 241.1,185.2 241.1,64.9 218.5,64.9 218.5,55.9 250.1,55.9" />
            <polygon points="179.7,154.6 173.3,148.3 196.3,125.1 173.8,101.8 180.2,95.6 208.9,125.2" />
        </svg>
    );
};

export default MaskDirectLogin;
