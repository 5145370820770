import { type FC } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { useFragment, graphql } from 'react-relay';
import { useIntl, defineMessages } from 'dibs-react-intl';
import AccountIcon from 'dibs-icons/exports/legacy/AccountOutlined';
import MaskIcon from 'dibs-icons/exports/legacy/Mask';
import MaskDirectLoginIcon from 'dibs-icons/exports/legacy/MaskDirectLogin';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const { STEP_INTERACTION_GLOBAL_NAV_USER_ICON_CLICK } = stepInteractionConstants;
import { Link } from 'dibs-elements/exports/Link';

import { type AccountNavTrigger_user$key } from './__generated__/AccountNavTrigger_user.graphql';

import styles from './AccountNavTrigger-style.scss';

type AccountNavTriggerProps = {
    isMasquerading: boolean;
    isDirectLogin: boolean;
    user: AccountNavTrigger_user$key;
};

const messages = defineMessages({
    label: { id: 'AccountNavTrigger.label', defaultMessage: 'Account' },
    tradeLabel: { id: 'AccountNavTrigger.tradeLabel', defaultMessage: 'Rewards Dashboard' },
});

const AccountNavTrigger: FC<AccountNavTriggerProps> = ({
    isMasquerading,
    isDirectLogin,
    user: userRef,
}) => {
    const intl = useIntl();
    const user = useFragment(
        graphql`
            fragment AccountNavTrigger_user on User {
                profile {
                    firstName
                }
                isVerifiedTrade
                tradeFirmRewards @include(if: $hasTradeUserId) {
                    currentYearPoints
                    currentProgram {
                        programInfo {
                            displayName
                        }
                    }
                }
            }
        `,
        userRef
    );

    let name: string | React.ReactElement;

    if (user?.profile?.firstName === undefined) {
        name = '';
    } else if (user?.profile?.firstName) {
        name = user?.profile?.firstName;
    } else {
        name = <FormattedMessage id="dbl.Header.AccountNav.name" defaultMessage={'My Account'} />;
    }

    const { tradeFirmRewards, isVerifiedTrade } = user || {};
    const useTradeDisplay = !!tradeFirmRewards;
    const currentYearPoints = Math.floor(tradeFirmRewards?.currentYearPoints || 0);
    const programDisplayName = tradeFirmRewards?.currentProgram?.programInfo?.displayName || '';
    const linkTarget = isVerifiedTrade ? '/trade/rewards/' : '/my/account/';
    let maskIcon = null;
    if (isMasquerading) {
        maskIcon = (
            <span data-tn="user-menu-masq-icon" className={styles.maskIcon}>
                <MaskIcon />
            </span>
        );
    } else if (isDirectLogin) {
        maskIcon = (
            <span data-tn="user-menu-direct-login-icon" className={styles.maskDirectLoginIcon}>
                <MaskDirectLoginIcon />
            </span>
        );
    }

    return (
        <div data-tn="user-menu-logged-in" className={styles.accountWrapper}>
            <div className={useTradeDisplay ? styles.nameWrapper : ''}>
                <Link
                    buyerLinkType="contrastLinkNoEffect"
                    dataTn="user-menu-logged-in-name"
                    className={useTradeDisplay ? styles.nameTrade : styles.name}
                    href={linkTarget}
                >
                    {maskIcon}
                    {isMasquerading || isDirectLogin ? <span>({name})</span> : name}
                </Link>
                <div>
                    {currentYearPoints > 0 && (
                        <Link
                            buyerLinkType="contrastLinkNoEffect"
                            className={styles.tradePoints}
                            href={linkTarget}
                            dataTn="user-menu-lifetime-points"
                        >
                            <FormattedMessage
                                id="dbl.Header.AccountNav.currentPoints"
                                defaultMessage="{currentYearPoints, plural, one {# pt} other {# pts}}"
                                values={{ currentYearPoints }}
                            />
                        </Link>
                    )}
                    {isVerifiedTrade && programDisplayName && (
                        <Link
                            buyerLinkType="contrastLinkNoEffect"
                            className={styles.tradeStatus}
                            href={linkTarget}
                            dataTn="user-menu-firm-program"
                        >
                            {programDisplayName}
                        </Link>
                    )}
                </div>
            </div>
            <Link
                ariaLabel={intl.formatMessage(
                    isVerifiedTrade ? messages.tradeLabel : messages.label
                )}
                className={styles.accountIcon}
                href={linkTarget}
                onClick={event => {
                    const label = 'user-nav|user icon';
                    trackEvent(
                        {
                            category: 'navigation',
                            action: 'header navigation click',
                            label,
                            eventName: EVENT_NAVIGATION,
                            interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                            step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_USER_ICON_CLICK,
                            trigger: label,
                        },
                        event
                    );
                }}
            >
                <AccountIcon />
            </Link>
        </div>
    );
};

export default AccountNavTrigger;
