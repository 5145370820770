/**
 * @generated SignedSource<<57e9ce639336e0652e695983f70a8fcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type ImageVersionTypeName = "bgProcessed" | "datamatics" | "large" | "master" | "medium" | "oldMaster" | "original" | "placeholder" | "small" | "thumb" | "transparent" | "zoom" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CartNav_viewer$data = {
  readonly isMultiItemCartFF: boolean | null;
  readonly items?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly isSold: boolean | null;
        readonly pdpURL: string | null;
        readonly photos: ReadonlyArray<{
          readonly versions: ReadonlyArray<{
            readonly type: {
              readonly name: ImageVersionTypeName | null;
            } | null;
            readonly webPath: string | null;
          } | null> | null;
        } | null> | null;
        readonly pricing: {
          readonly netPrice: {
            readonly amount: number | null;
            readonly convertedAmountList: ReadonlyArray<{
              readonly amount: number | null;
              readonly currency: Currency | null;
            } | null> | null;
            readonly currency: Currency | null;
          } | null;
          readonly retailPrice: {
            readonly amount: number | null;
            readonly convertedAmountList: ReadonlyArray<{
              readonly amount: number | null;
              readonly currency: Currency | null;
            } | null> | null;
            readonly currency: Currency | null;
          } | null;
          readonly salePrice: {
            readonly amount: number | null;
            readonly convertedAmountList: ReadonlyArray<{
              readonly amount: number | null;
              readonly currency: Currency | null;
            } | null> | null;
            readonly currency: Currency | null;
          } | null;
        } | null;
        readonly serviceId: string | null;
        readonly title: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly portfolios?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly items: {
          readonly edges?: ReadonlyArray<{
            readonly node: {
              readonly item: {
                readonly isSold: boolean | null;
                readonly pdpURL: string | null;
                readonly photos: ReadonlyArray<{
                  readonly versions: ReadonlyArray<{
                    readonly type: {
                      readonly name: ImageVersionTypeName | null;
                    } | null;
                    readonly webPath: string | null;
                  } | null> | null;
                } | null> | null;
                readonly pricing: {
                  readonly netPrice: {
                    readonly amount: number | null;
                    readonly convertedAmountList: ReadonlyArray<{
                      readonly amount: number | null;
                      readonly currency: Currency | null;
                    } | null> | null;
                    readonly currency: Currency | null;
                  } | null;
                  readonly retailPrice: {
                    readonly amount: number | null;
                    readonly convertedAmountList: ReadonlyArray<{
                      readonly amount: number | null;
                      readonly currency: Currency | null;
                    } | null> | null;
                    readonly currency: Currency | null;
                  } | null;
                  readonly salePrice: {
                    readonly amount: number | null;
                    readonly convertedAmountList: ReadonlyArray<{
                      readonly amount: number | null;
                      readonly currency: Currency | null;
                    } | null> | null;
                    readonly currency: Currency | null;
                  } | null;
                } | null;
                readonly serviceId: string | null;
                readonly title: string | null;
              } | null;
            } | null;
          } | null> | null;
          readonly totalResults: number | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly userCartTotals: {
    readonly savedItemsTotal: number | null;
  } | null;
  readonly " $fragmentType": "CartNav_viewer";
};
export type CartNav_viewer$key = {
  readonly " $data"?: CartNav_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartNav_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "pdpURL",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serviceId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isSold",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ItemPhoto",
    "kind": "LinkedField",
    "name": "photos",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ItemPhotoVersion",
        "kind": "LinkedField",
        "name": "versions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "webPath",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ImageVersionType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userCountryCode",
        "variableName": "userCountryCode"
      }
    ],
    "concreteType": "ItemPricing",
    "kind": "LinkedField",
    "name": "pricing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAmount",
        "kind": "LinkedField",
        "name": "netPrice",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAmount",
        "kind": "LinkedField",
        "name": "salePrice",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FinancialAmount",
        "kind": "LinkedField",
        "name": "retailPrice",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "cartCountItems"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchLocalItems"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchPortfolioItems"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "localItemIds"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartNav_viewer",
  "selections": [
    {
      "alias": "isMultiItemCartFF",
      "args": [
        {
          "kind": "Literal",
          "name": "feature",
          "value": "multiItemCart"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": "featureFlag(feature:\"multiItemCart\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CartTotals",
      "kind": "LinkedField",
      "name": "userCartTotals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "savedItemsTotal",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "fetchLocalItems",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 3
            },
            {
              "kind": "Variable",
              "name": "itemIds",
              "variableName": "localItemIds"
            }
          ],
          "concreteType": "ItemsConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Item",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            },
            {
              "kind": "Literal",
              "name": "portfolioTypes",
              "value": [
                "CART"
              ]
            },
            {
              "kind": "Variable",
              "name": "userIds",
              "variableName": "userIds"
            }
          ],
          "concreteType": "PortfoliosConnection",
          "kind": "LinkedField",
          "name": "portfolios",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PortfoliosEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PortfolioV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "first",
                          "variableName": "cartCountItems"
                        }
                      ],
                      "concreteType": "PortfolioItemsConnection",
                      "kind": "LinkedField",
                      "name": "items",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalResults",
                          "storageKey": null
                        },
                        {
                          "condition": "fetchPortfolioItems",
                          "kind": "Condition",
                          "passingValue": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PortfolioItemsEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "PortfolioItemV2",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Item",
                                      "kind": "LinkedField",
                                      "name": "item",
                                      "plural": false,
                                      "selections": (v3/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ]
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "85667bb78b47c5aa45bdc85deee20069";

export default node;
