import { type SyntheticEvent, type FunctionComponent } from 'react';
import ArrowUp from 'dibs-icons/exports/legacy/ArrowUp';
import classnames from 'classnames';

import { SIZES, DIRECTIONS } from '../Common/constants';

import styles from './main.scss';

const DEFAULT = 'default';
const WHITE_FILL = 'whiteFill';
type BLACK_FILL = 'blackFill';
const LINK_PRIMARY = 'linkPrimary';
const noop = (): void => {};

type RotatingArrowProps = {
    direction: typeof DIRECTIONS.up | typeof DIRECTIONS.down;
    onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
    onMouseUp?: (e: SyntheticEvent<HTMLDivElement>) => void;
    paddingRight?: typeof SIZES.small | typeof SIZES.medium;
    size?: typeof SIZES.small | typeof SIZES.medium | typeof SIZES.headerXLarge;
    type?: typeof DEFAULT | typeof LINK_PRIMARY | typeof WHITE_FILL | BLACK_FILL;
    isDisabled?: boolean;
    shouldTriggerHover?: boolean;
};

export const RotatingArrow: FunctionComponent<RotatingArrowProps> = ({
    direction,
    onClick,
    onMouseUp,
    paddingRight,
    size = SIZES.medium,
    type = DEFAULT,
    isDisabled = false,
    shouldTriggerHover = false,
}) => {
    const containerClasses = classnames(styles.arrowContainer, {
        [styles.paddingRightSmall]: paddingRight === SIZES.small,
        [styles.paddingRight]: paddingRight === SIZES.medium,
    });
    let styleTypeHover = styles.defaultHover;
    if (type === 'linkPrimary') {
        styleTypeHover = styles.linkPrimaryHover;
    }
    return (
        <div
            className={containerClasses}
            onClick={isDisabled ? noop : onClick}
            onMouseUp={isDisabled ? noop : onMouseUp}
            onKeyDown={isDisabled ? noop : onClick}
            role="button"
            tabIndex={0}
        >
            <ArrowUp
                className={classnames(styles.arrow, {
                    [styles.arrowDown]: direction === DIRECTIONS.down,
                    [styles.sizeSmall]: size === SIZES.small,
                    [styles.sizeMedium]: size === SIZES.medium,
                    [styles.sizeHeaderXLarge]: size === SIZES.headerXLarge,
                    [styles.default]: type === DEFAULT,
                    [styles.whiteFill]: type === WHITE_FILL,
                    [styles.linkPrimary]: type === LINK_PRIMARY,
                    [styleTypeHover]: shouldTriggerHover,
                    [styles.disabled]: isDisabled,
                })}
            />
        </div>
    );
};
