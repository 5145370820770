/* eslint-disable relay/unused-fields */
// terms, term & urlLabel are not used here
// suggestions are being filtered and then are passed to component
// Once isPopularSearchesEnabledByLocale related logic is removed
// searchSuggestions fragment needs to be moved to the component with direct usage
import { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { cleanUpRecentSearches, dedupeLocalStorage } from '../utils/sharedSearchBarHelpers';
import { SEARCH_BAR_TRACKING } from '../utils/sharedSearchBarConstants';
import {
    type useSearchSuggestions_viewer$data,
    type useSearchSuggestions_viewer$key,
} from './__generated__/useSearchSuggestions_viewer.graphql';

const viewerFragment = graphql`
    fragment useSearchSuggestions_viewer on Viewer
    @argumentDefinitions(
        query: { type: "String", defaultValue: "" }
        hasQuery: { type: "Boolean", defaultValue: false }
    ) {
        isPopularSearchesEnabledByLocale: isEnabledByLocale(featureName: "POPULAR_SEARCHES")
        searchSuggestions(query: $query, includeTypes: $includeTypes) @include(if: $hasQuery) {
            suggestions {
                type
                terms {
                    term
                    urlLabel
                }
            }
        }
    }
`;

export type Suggestions = NonNullable<
    useSearchSuggestions_viewer$data['searchSuggestions']
>['suggestions'];

export type useSearchSuggestionsReturn = {
    suggestions?: Suggestions;
    trackEventLabel: string;
    recentSearches: NonNullable<Suggestions>;
};

export const useSearchSuggestions = ({
    viewer: viewerRef,
}: {
    viewer: useSearchSuggestions_viewer$key;
}): useSearchSuggestionsReturn => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const { searchSuggestions, isPopularSearchesEnabledByLocale } = viewer || {};
    const suggestions = searchSuggestions?.suggestions
        ? searchSuggestions.suggestions.filter(suggestion => {
              if (suggestion?.type !== 'POPULAR') {
                  return true;
              }
              return isPopularSearchesEnabledByLocale;
          })
        : undefined;

    const recentSearches = dedupeLocalStorage() || [];

    useEffect(() => {
        cleanUpRecentSearches();
    }, []);

    const trackEventLabel = searchSuggestions
        ? SEARCH_BAR_TRACKING.LABELS.GLOBAL_SEARCH_AUTOCOMPLETE
        : SEARCH_BAR_TRACKING.LABELS.RECENT_SEARCH;

    return {
        suggestions,
        recentSearches,
        trackEventLabel,
    };
};
