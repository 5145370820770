import { type FC } from 'react';
import { useIntl, defineMessages } from 'dibs-react-intl';

import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const { STEP_INTERACTION_GLOBAL_NAV_LIKES_ICON_CLICK } = stepInteractionConstants;
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';
import HeartIcon from 'dibs-icons/exports/legacy/HeartOutlined';
import { trackSellerBrandingRemovalAbTestVariant } from '../../utils/abTest/sellerBrandingRemovalAbTestHelpers';
import styles from './Likes-style.scss';

const label = 'my favorites|my favorites header icon';
const trackingObj = {
    category: 'navigation',
    action: 'header navigation click',
    label,
    eventName: EVENT_NAVIGATION,
    interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
    step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_LIKES_ICON_CLICK,
    trigger: label,
};

type Props = {
    onMouseEnter?: () => void;
    favoritesUrl?: string;
    hasUnread?: boolean;
};
const messages = defineMessages({
    label: { id: 'dbl.Header.LikesIcon.label', defaultMessage: 'Favorites' },
});
const LikesIcon: FC<Props> = ({ onMouseEnter, favoritesUrl, hasUnread = false }) => {
    const intl = useIntl();
    const iconClassName = classnames(styles.icon, {
        [styles.hasUnread]: hasUnread,
    });

    return (
        <div className={styles.iconWrapper} onMouseEnter={onMouseEnter}>
            <Link
                ariaLabel={intl.formatMessage(messages.label)}
                dataTn="likes-link"
                href={favoritesUrl}
                className={iconClassName}
                onClick={event => {
                    trackEvent(trackingObj, event);
                    trackSellerBrandingRemovalAbTestVariant();
                }}
            >
                <HeartIcon />
            </Link>
        </div>
    );
};

export default LikesIcon;
