import { Component } from 'react';
import { object, bool, string, func, array, number } from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import { FormattedMessage } from 'dibs-react-intl';
import { localStorage } from 'dibs-browser-storage';
import classnames from 'classnames';

import { addCartItemMutation } from './addCartItemMutation';
import { addMultiCartItems } from './addMultiCartItemsMutation';
import {
    removeLocalCart as removeLocalCartAction,
    loadLocalCart,
} from '../../../src/actions/cartActions';
import { actionCreators } from '../../../src/actions';
import Dropdown, { alignConstants } from '../Dropdown/Dropdown';
import DropdownHeader from '../Dropdown/DropdownHeader';
import DropdownBody from '../Dropdown/DropdownBody';
import CartNavDropdownTrigger from './CartNavDropdownTrigger';
import CartNavDropdownLoadingContent from '../Dropdown/DropdownLoadingContent';
import { loadCartNavDropdownContent } from './loadCartNavDropdownContent';
import { CurrencyProvider } from '../CurrencyProvider/CurrencyProvider';
import { wasPortfolioItemRemoved } from '../../helpers/cartHelpers';
import { getUserSessionCountryCode } from 'dibs-regional-info/exports/regionalInfoHelpers';

import styles from './CartNav-style.scss';

class CartNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            CartNavDropdownContent: null,
            fragmentVariables: {
                fetchLocalItems: false,
                fetchPortfolioItems: false,
                itemIds: '',
                userIds: '',
                userCountryCode: getUserSessionCountryCode(),
                cartCountItems: 0,
            },
        };

        this.triggerItemsFetch = this.triggerItemsFetch.bind(this);
        this.loadComponent = this.loadComponent.bind(this);
    }

    componentDidMount() {
        if (!this.props.user) {
            const isMultiItemCartFF = !!this.props.viewer?.isMultiItemCartFF;
            this.props.loadLocalCart(isMultiItemCartFF);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            relay,
            user,
            userId,
            localCartItems,
            removedPortfolioCartItemId,
            removeLocalCart,
            setAddCartItemMutationCompleted,
            cartRefetchTrigger,
        } = this.props;
        const {
            removedPortfolioCartItemId: prevRemovedCartItemId,
            cartRefetchTrigger: prevCartRefetchTrigger,
        } = prevProps;

        if (
            !!user &&
            (wasPortfolioItemRemoved({ prevRemovedCartItemId, removedPortfolioCartItemId }) ||
                cartRefetchTrigger !== prevCartRefetchTrigger)
        ) {
            this.fetchPortfolioItems();
        }

        if (!!user && localCartItems.length > 0) {
            if (this.props.viewer?.isMultiItemCartFF) {
                addMultiCartItems({
                    items: localCartItems,
                    onCompleted: setAddCartItemMutationCompleted,
                    removeLocalCart,
                    environment: relay.environment,
                    onFailedItems: failedItemIds => {
                        localStorage.setItem('failedCartSyncItems', failedItemIds);
                    },
                });
            } else {
                addCartItemMutation({
                    environment: relay.environment,
                    itemsIds: localCartItems.map(({ id }) => id),
                    userId,
                    onCompleted: () => {
                        this.onCompleted();
                    },
                    onError: error => {
                        this.onError(error);
                    },
                });
            }
        }
    }

    onCompleted() {
        this.props.removeLocalCart();
        this.triggerItemsFetch();
        this.props.setAddCartItemMutationCompleted();
    }
    onError(error) {
        const { source } = error.getError ? error.getError() : {};

        if (source) {
            const message = source?.errors?.[0]?.message;
            const isDuplicate = message.includes('409');

            if (isDuplicate) {
                this.props.removeLocalCart();
            }
        }
    }

    /**
     * Inventory SOLR doesn't guarantee returning items in the order itemIds were passed to it
     * Sort response from Inventory SOLR by order of itemIds in local cart
     */
    getSortedLocalItems() {
        const { viewer, localCartItems } = this.props;
        const itemIds = localCartItems.map(id => id);
        const localItems = viewer?.items;

        if (!localItems || !itemIds) {
            return null;
        }

        if (itemIds.length < 2 || (!!localItems && localItems.length > 0)) {
            return localItems;
        }

        const map = itemIds.reduce((acc, itemId, index) => {
            acc[itemId] = index;
            return acc;
        }, {});

        const edges = localItems.edges.slice();

        edges.sort(({ node: nodeA }, { node: nodeB }) => {
            const { serviceId: a } = nodeA;
            const { serviceId: b } = nodeB;
            return map[a] - map[b];
        });

        return localItems;
    }

    isEmptyState() {
        const { hasUserId, localCartItems } = this.props;

        if (!hasUserId && localCartItems.length === 0) {
            return true;
        }

        const items = this.getItems();
        return !!(items && items.edges && items.edges.length === 0);
    }

    getItems() {
        const { hasUserId, viewer } = this.props;

        return hasUserId ? viewer?.portfolios?.edges?.[0]?.node?.items : this.getSortedLocalItems();
    }

    getCount() {
        const { hasUserId, viewer, localCartItems = [] } = this.props;
        const localItemsCount = viewer?.isMultiItemCartFF
            ? localCartItems?.reduce((totalCount, localItem) => {
                  if (localItem?.type === 'CHECKOUT') {
                      totalCount += localItem.quantity;
                  }
                  return totalCount;
              }, 0)
            : localCartItems.length;
        return hasUserId
            ? viewer?.portfolios?.edges?.[0]?.node?.items?.totalResults || 0
            : localItemsCount;
    }

    getMultiCartSavedItemsCount() {
        const { hasUserId, viewer, localCartItems = [] } = this.props;
        if (viewer?.isMultiItemCartFF) {
            const localItemsCount = localCartItems?.reduce((totalCount, localItem) => {
                if (localItem?.type === 'SAVED_FOR_LATER') {
                    totalCount += localItem.quantity;
                }
                return totalCount;
            }, 0);
            return hasUserId ? viewer?.userCartTotals?.savedItemsTotal : localItemsCount;
        }
        return 0;
    }

    triggerItemsFetch() {
        const { hasRootPendingRefetch, hasUserId } = this.props;

        if (hasRootPendingRefetch) {
            return;
        }
        if (hasUserId && !this.state.fragmentVariables.fetchPortfolioItems) {
            this.fetchPortfolioItems();
        }

        if (!hasUserId && !this.state.fragmentVariables.fetchLocalItems) {
            this.fetchLocalItems();
        }
    }

    fetchPortfolioItems() {
        this.setState(
            {
                fragmentVariables: {
                    ...this.state.fragmentVariables,
                    hasUserId: this.props.hasUserId,
                    fetchLocalItems: false,
                    fetchPortfolioItems: true,
                    userIds: [this.props.userId],
                    userCountryCode: getUserSessionCountryCode(),
                    cartCountItems: 3,
                },
            },
            () => {
                this.props.relay.refetch(this.state.fragmentVariables, null);
            }
        );
    }

    fetchLocalItems() {
        const { localCartItems } = this.props;
        const itemIds = localCartItems.map(({ id }) => id);

        if (itemIds.length > 0) {
            this.setState(
                {
                    fragmentVariables: {
                        ...this.state.fragmentVariables,
                        fetchLocalItems: true,
                        localItemIds: itemIds,
                        userCountryCode: getUserSessionCountryCode(),
                        cartCountItems: 3,
                    },
                },
                () => {
                    this.props.relay.refetch(this.state.fragmentVariables, null);
                }
            );
        }
    }

    loadComponent() {
        loadCartNavDropdownContent().then(CartNavDropdownContent => {
            this.setState({ CartNavDropdownContent });
        });
    }
    render() {
        const { isClient, isVipCuratedFolderTooltipVisible, viewer } = this.props;
        const { CartNavDropdownContent } = this.state;
        const isMultiItemCartFF = !!viewer?.isMultiItemCartFF;

        return isClient ? (
            <Dropdown
                align={alignConstants.ALIGN_RIGHT}
                onShow={this.loadComponent}
                hasDropdownHeader
                className={classnames({ [styles.noHover]: isVipCuratedFolderTooltipVisible })}
                bodyClassName={styles.dropdownBodyWrapper}
                dropdownClassName={styles.dropdown}
            >
                <CartNavDropdownTrigger
                    count={this.getCount()}
                    handleMouseEnter={this.triggerItemsFetch}
                />
                <DropdownHeader
                    title={
                        <FormattedMessage
                            id="dbl.Header.CartNav.shoppingBagHeaderTitle"
                            defaultMessage="My Shopping {isMultiItemCartFF, select, true {Cart} other {Bag}}"
                            values={{ isMultiItemCartFF }}
                        />
                    }
                    link={
                        <FormattedMessage
                            id="dbl.Header.CartNav.headerLink"
                            defaultMessage="View All"
                        />
                    }
                    href="/my/cart/"
                />
                <DropdownBody>
                    {CartNavDropdownContent ? (
                        <CurrencyProvider
                            render={({ currency }) => (
                                <CartNavDropdownContent
                                    isMultiItemCartFF={isMultiItemCartFF}
                                    currency={currency}
                                    isEmptyState={this.isEmptyState()}
                                    multiCartSavedItemsCount={this.getMultiCartSavedItemsCount()}
                                    items={this.getItems()}
                                    totalItems={this.getCount()}
                                />
                            )}
                        />
                    ) : (
                        <CartNavDropdownLoadingContent />
                    )}
                </DropdownBody>
            </Dropdown>
        ) : (
            <CartNavDropdownTrigger />
        );
    }
}

CartNav.propTypes = {
    hasRootPendingRefetch: bool,
    setAddCartItemMutationCompleted: func.isRequired,
    hasUserId: bool,
    isClient: bool,
    relay: object,
    user: object,
    userId: string,
    viewer: object,
    localCartItems: array,
    removedPortfolioCartItemId: string,
    removeLocalCart: func.isRequired,
    loadLocalCart: func.isRequired,
    isVipCuratedFolderTooltipVisible: bool,
    cartRefetchTrigger: number,
};

/* eslint-disable no-unused-expressions */
// these queries are used in async chunks
graphql`
    fragment CartNav_financialAmount on FinancialAmount {
        amount
        currency
        convertedAmountList {
            currency
            amount
        }
    }
`;

graphql`
    fragment CartNav_item on Item {
        pdpURL
        serviceId
        title
        isSold
        photos {
            versions {
                webPath
                type {
                    name
                }
            }
        }
        pricing(userCountryCode: $userCountryCode) {
            netPrice {
                ...CartNav_financialAmount @relay(mask: false)
            }
            salePrice {
                ...CartNav_financialAmount @relay(mask: false)
            }
            retailPrice {
                ...CartNav_financialAmount @relay(mask: false)
            }
        }
    }
`;
/* eslint-enable no-unused-expressions */

const mapStateToProps = ({ header, cart }) => {
    const localCartItems = cart?.items || [];
    const removedPortfolioCartItemId = cart?.removedPortfolioCartItemId;
    const isVipCuratedFolderTooltipVisible = header?.isVipCuratedFolderTooltipVisible;
    return {
        localCartItems,
        removedPortfolioCartItemId,
        isVipCuratedFolderTooltipVisible,
        cartRefetchTrigger: cart?.cartRefetchTrigger,
    };
};

const mapDispatchToProps = dispatch => ({
    setAddCartItemMutationCompleted: () =>
        dispatch(actionCreators.setAddCartItemMutationCompleted()),
    removeLocalCart: () => dispatch(removeLocalCartAction()),
    loadLocalCart: isMultiItemCartFF => dispatch(loadLocalCart(isMultiItemCartFF)),
});

export default createRefetchContainer(
    connect(mapStateToProps, mapDispatchToProps)(CartNav),
    {
        user: graphql`
            fragment CartNav_user on User {
                preferences {
                    currency
                }
            }
        `,
        viewer: graphql`
            fragment CartNav_viewer on Viewer
            @argumentDefinitions(
                fetchPortfolioItems: { type: "Boolean", defaultValue: false }
                fetchLocalItems: { type: "Boolean", defaultValue: false }
                localItemIds: { type: "[String]", defaultValue: [] }
                cartCountItems: { type: "Int", defaultValue: 0 }
            ) {
                isMultiItemCartFF: featureFlag(feature: "multiItemCart")
                userCartTotals {
                    savedItemsTotal
                }
                items(itemIds: $localItemIds, first: 3) @include(if: $fetchLocalItems) {
                    edges {
                        node {
                            ...CartNav_item @relay(mask: false)
                        }
                    }
                }
                portfolios(userIds: $userIds, portfolioTypes: [CART], first: 1)
                    @include(if: $hasUserId) {
                    edges {
                        node {
                            items(first: $cartCountItems) {
                                totalResults
                                edges @include(if: $fetchPortfolioItems) {
                                    node {
                                        item {
                                            ...CartNav_item @relay(mask: false)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
    },
    graphql`
        query CartNavRefetchQuery(
            $fetchLocalItems: Boolean!
            $fetchPortfolioItems: Boolean!
            $hasUserId: Boolean!
            $localItemIds: [String]
            $userIds: [String!]
            $userCountryCode: String = ""
            $cartCountItems: Int = 0
        ) {
            viewer {
                ...CartNav_viewer
                    @arguments(
                        fetchLocalItems: $fetchLocalItems
                        fetchPortfolioItems: $fetchPortfolioItems
                        localItemIds: $localItemIds
                        cartCountItems: $cartCountItems
                    )
            }
        }
    `
);
