/**
 * @generated SignedSource<<73c9cab7c3f2198ebf9b363e56044407>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteNav_viewer$data = {
  readonly navigation: {
    readonly modules: ReadonlyArray<{
      readonly serviceId: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SiteNavItem_navigationModule">;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "SiteNav_viewer";
};
export type SiteNav_viewer$key = {
  readonly " $data"?: SiteNav_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteNav_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "previewKey"
    },
    {
      "kind": "RootArgument",
      "name": "variantId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteNav_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "rootId",
          "variableName": "previewKey"
        },
        {
          "kind": "Variable",
          "name": "variantId",
          "variableName": "variantId"
        }
      ],
      "concreteType": "Navigation",
      "kind": "LinkedField",
      "name": "navigation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NavigationModule",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SiteNavItem_navigationModule"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "8957803dc4f7fd4323dbe9a68f6bb3b7";

export default node;
