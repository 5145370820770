/**
 * @generated SignedSource<<3c28f819cf90d8eea1f08dfe31c2ad8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopBar_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountNav_viewer" | "CartNav_viewer" | "DisplaySettingsModal_viewer" | "Likes_viewer" | "Notifications_initialViewer" | "Notifications_viewer" | "SearchBar_viewer">;
  readonly " $fragmentType": "TopBar_viewer";
};
export type TopBar_viewer$key = {
  readonly " $data"?: TopBar_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopBar_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopBar_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CartNav_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Likes_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Notifications_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Notifications_initialViewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBar_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountNav_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DisplaySettingsModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "bd2bb50167e11227c739c79bc8ef47e7";

export default node;
