import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type AlertBellProps = {
    className?: string;
};

const AlertBell: FunctionComponent<AlertBellProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'alert-bell'}
            viewBox="0 0 250 250"
        >
            <path d="M246.3 194.1c-1.3-.2-31-4.4-30.1-58.4V85.4v-.2c-.1-2.3-2.7-55.9-47.1-74.6-3.4-1.8-43.1-21.2-82.5.1-3.1 1.5-44.9 22.5-46 74.2v52.5c0 .3.6 31.9-14.7 47.8-5.6 5.9-12.6 8.8-21.2 8.9-2.5 0-4.5 2.1-4.4 4.5 0 2.5 2 4.5 4.5 4.5h77.8c.3 3.8 1.4 12.9 5.7 22.1 7.5 16.1 20.8 24.4 38.5 24.1h.8c3.4 0 14.3-.7 24-9.2 9.2-8 14.5-20.5 15.8-37h78.4c2.4 0 4.3-1.9 4.5-4.2s-1.7-4.5-4-4.8zm-119.2 46.1h-.4c-29.8.6-34.5-29-35.2-37.2h66.8c-3.3 38.4-30 37.3-31.2 37.2zm-97.5-46.1c1-.8 1.9-1.7 2.8-2.6 17.7-18.4 17.2-52 17.2-54.2V85c1-46.8 39.3-65.5 40.9-66.2.1 0 .2-.1.2-.1 36.1-19.5 74-.2 74.4 0 .1.1.2.1.4.2C204 35 207.1 83.3 207.2 85.5v50.1c-.5 25.9 5.6 45 18 57 .5.5 1.1 1 1.6 1.5H29.6z" />
        </svg>
    );
};

export default AlertBell;
