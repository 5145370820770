/**
 * @generated SignedSource<<d0c2da79dbc2f41f71933d695c309a40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Notifications_viewer$data = {
  readonly conversations?: {
    readonly " $fragmentSpreads": FragmentRefs<"Conversations_conversations">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"promoHelpers_trackGeoLocationPromos_viewer">;
  readonly " $fragmentType": "Notifications_viewer";
};
export type Notifications_viewer$key = {
  readonly " $data"?: Notifications_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Notifications_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "buyerId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchNotifications"
    },
    {
      "defaultValue": 5,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Notifications_viewer",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "promoHelpers_trackGeoLocationPromos_viewer",
      "selections": [
        {
          "condition": "isGeoLocationPromoGroupSet",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "listTypes",
                  "value": [
                    "ABTEST_PROMOTIONS"
                  ]
                },
                {
                  "kind": "Variable",
                  "name": "userId",
                  "variableName": "userId"
                }
              ],
              "concreteType": "PromotionVariantControl",
              "kind": "LinkedField",
              "name": "promotionVariantControl",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "promoGroup",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "promotionCode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "args": null,
      "argumentDefinitions": [
        {
          "kind": "RootArgument",
          "name": "isGeoLocationPromoGroupSet"
        },
        {
          "kind": "RootArgument",
          "name": "userId"
        }
      ]
    },
    {
      "condition": "fetchNotifications",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "conversations",
          "args": [
            {
              "kind": "Variable",
              "name": "buyerId",
              "variableName": "buyerId"
            },
            {
              "kind": "Literal",
              "name": "filter",
              "value": "showUnreadOnly"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            }
          ],
          "concreteType": "ConversationsSolrConnection",
          "kind": "LinkedField",
          "name": "conversationsSolr",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Conversations_conversations"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "4836174e806b83407e405da59412ae72";

export default node;
