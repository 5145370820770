import { type Environment } from 'react-relay';

import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import { getRedirectDestination } from './getRedirectDestination';
import { emailLoginMutation } from '../authModal/authentication/login/emailLoginMutation';
import {
    trackEmailLoginLinkComplete,
    trackVerificationFailedModalDisplayed,
    trackEmailOnlyCheckoutEmailLoginComplete,
} from '../authModal/authentication/authTracking';

const { EMAIL_LINK_LOGIN_RESEND_EMAIL_FLOW } = authModalLoader.constants;

export const loginUserWithEmailLoginToken = async (
    environment: Environment,
    emailLoginToken: string,
    refetchViewer: () => void
): Promise<{ isPageReloading: boolean }> => {
    let isPageReloading = false;

    try {
        await emailLoginMutation(environment, {
            emailLoginToken,
        });

        // TODO add tracking for stale user once labels are approved
        trackEmailLoginLinkComplete();
        trackEmailOnlyCheckoutEmailLoginComplete();

        const destination = getRedirectDestination();
        if (destination) {
            isPageReloading = true;
            window.location.href = handleLocaleUrl(destination, GLOBAL_CLIENT_ONLY_LOCALE);
        } else {
            const searchParams = new URLSearchParams(window.location.search);
            const hasEmailLoginToken = searchParams.has('emailLoginToken');
            if (hasEmailLoginToken) {
                searchParams.delete('emailToken');
                searchParams.delete('emailLoginToken');
                searchParams.delete('email');
                isPageReloading = true;
                window.location.replace(
                    handleLocaleUrl(
                        `${window.location.pathname}?${searchParams.toString()}`,
                        GLOBAL_CLIENT_ONLY_LOCALE
                    )
                );
            } else {
                refetchViewer();
            }
        }
    } catch (error) {
        const email = new URLSearchParams(window.location.search).get('email');
        // TODO add tracking for stale user once labels are approved
        trackVerificationFailedModalDisplayed();
        authModalLoader.show({
            action: authModalLoader.constants.REQUEST_PASSWORD_EMAIL,
            email,
            flow: EMAIL_LINK_LOGIN_RESEND_EMAIL_FLOW,
        });
    }

    return {
        isPageReloading,
    };
};
