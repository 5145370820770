import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FC } from 'react';

type MagnifyingGlassProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const MagnifyingGlass: FC<MagnifyingGlassProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'magnifying-glass'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 250 250"
        >
            <path d="M105.5 211.3C47.5 211.3.3 164.1.3 106.1.4 48.2 47.5 1 105.5 1s105.2 47.2 105.2 105.2-47.2 105.1-105.2 105.1zm0-190.3c-47 0-85.2 38.2-85.2 85.2s38.2 85.2 85.2 85.2 85.2-38.2 85.2-85.2S152.5 21 105.5 21z" />
            <path d="M239.9 250c-2.5 0-5-.9-6.9-2.8l-67.7-64.9c-4-3.8-4.1-10.2-.3-14.1 3.8-4 10.2-4.1 14.1-.3l67.7 64.9c4 3.8 4.1 10.2.3 14.1-2 2.1-4.6 3.1-7.2 3.1z" />
        </svg>
    );
};

export default MagnifyingGlass;
