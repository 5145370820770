/**
 * @generated SignedSource<<abbb8d1f813ab98df6643bd4cd4134fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Header_viewer$data = {
  readonly regionalInfo?: ReadonlyArray<{
    readonly currency: string | null;
    readonly incomeLevel: {
      readonly incomeBracket: string | null;
      readonly incomePercentile: string | null;
    } | null;
  } | null> | null;
  readonly relayIsClient: "Viewer";
  readonly user?: {
    readonly isVerifiedTrade: boolean | null;
    readonly isVip: boolean | null;
    readonly preferences: {
      readonly currency: Currency | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"TopBar_user" | "userTracking_user">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BottomBar_viewer" | "TopBar_viewer" | "userTracking_viewer">;
  readonly " $fragmentType": "Header_viewer";
};
export type Header_viewer$key = {
  readonly " $data"?: Header_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "zipCode",
  "variableName": "zipCode"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchRegionalInfo"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "isClient"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    },
    {
      "kind": "RootArgument",
      "name": "zipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_viewer",
  "selections": [
    {
      "condition": "isClient",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "relayIsClient",
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "Defer",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "userTracking_viewer"
            }
          ]
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TopBar_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BottomBar_viewer"
    },
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            (v0/*: any*/)
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "Defer",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "userTracking_user"
                }
              ]
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TopBar_user"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isVip",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isVerifiedTrade",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserPreferences",
              "kind": "LinkedField",
              "name": "preferences",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "fetchRegionalInfo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            },
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "concreteType": "RegionalInfo",
          "kind": "LinkedField",
          "name": "regionalInfo",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": [
                (v2/*: any*/)
              ],
              "concreteType": "IncomeLevel",
              "kind": "LinkedField",
              "name": "incomeLevel",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "incomeBracket",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "incomePercentile",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "ce13d1aacb4891d04e41691d89bb76c1";

export default node;
