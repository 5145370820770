/**
 * @generated SignedSource<<bc21a3b6c8f4a56d54eb36cecf668d24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountNavContent_user$data = {
  readonly conversationSummary: {
    readonly unread: number | null;
    readonly unreadActiveOffers: number | null;
  } | null;
  readonly ethereumWalletAddress: {
    readonly address: string | null;
  } | null;
  readonly profile: {
    readonly email: string | null;
  } | null;
  readonly " $fragmentType": "AccountNavContent_user";
};
export type AccountNavContent_user$key = {
  readonly " $data"?: AccountNavContent_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountNavContent_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountNavContent_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Profile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Summary",
      "kind": "LinkedField",
      "name": "conversationSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unread",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadActiveOffers",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EthereumAddressType",
      "kind": "LinkedField",
      "name": "ethereumWalletAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4be9df994a6403ff5a246016b0b1e5f3";

export default node;
