/**
 * @generated SignedSource<<cadc46f8908bb74024c36c6c648396ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteNavDropdownContent_navigationModule$data = {
  readonly imageUrl: string | null;
  readonly items: ReadonlyArray<{
    readonly column: string | null;
    readonly items: ReadonlyArray<{
      readonly name: string | null;
    } | null> | null;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SiteNavDropdownColumn_navigationTopLevel">;
  } | null> | null;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SiteNavDropdownImageModule_navigationModule">;
  readonly " $fragmentType": "SiteNavDropdownContent_navigationModule";
};
export type SiteNavDropdownContent_navigationModule$key = {
  readonly " $data"?: SiteNavDropdownContent_navigationModule$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteNavDropdownContent_navigationModule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteNavDropdownContent_navigationModule",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NavigationTopLevel",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "column",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NavigationItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SiteNavDropdownColumn_navigationTopLevel"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteNavDropdownImageModule_navigationModule"
    }
  ],
  "type": "NavigationModule",
  "abstractKey": null
};
})();

(node as any).hash = "3428b7f5819420900f4d6e7be067e533";

export default node;
