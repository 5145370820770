import { type SyntheticEvent, type FC, type ReactNode } from 'react';
import classnames from 'classnames';

import styles from './main.scss';

import { type SIZES } from '../constants';

type InputBorderContainerProps = {
    children: ReactNode;
    disabled?: boolean;
    hasError?: boolean;
    noBorder?: boolean;
    isHeaderInput?: boolean;
    isFocused?: boolean;
    maskForPrivacy?: boolean;
    onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
    size?: typeof SIZES.small | typeof SIZES.medium | typeof SIZES.large;
};

export const InputBorderContainer: FC<InputBorderContainerProps> = ({
    children,
    disabled,
    hasError,
    isFocused,
    maskForPrivacy,
    onClick,
    size,
    noBorder,
    isHeaderInput,
}) => {
    const inputContainerClasses = classnames(styles.inputContainer, {
        [styles.heightDefault]: !size,
        [styles.heightSmall]: size === 'small',
        [styles.heightMedium]: size === 'medium',
        [styles.heightLarge]: size === 'large',
        [styles.borderError]: hasError,
        [styles.borderFocus]: !hasError && isFocused,
        [styles.borderDark]: isHeaderInput,
        [styles.noBorder]: noBorder && !hasError,
        [styles.disabled]: disabled,
        'fs-block': maskForPrivacy,
    });

    let containerProps = {};
    if (onClick) {
        containerProps = {
            onClick,
        };
    }
    return (
        <div className={inputContainerClasses} {...containerProps}>
            {children}
        </div>
    );
};
