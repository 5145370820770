import { graphql } from 'react-relay/legacy';

export default graphql`
    query headerRootQuery(
        $hasUserId: Boolean!
        $hasTradeUserId: Boolean!
        $previewKey: String = ""
        $variantId: String = ""
        $userId: String!
        $userIds: [String]!
        $isClient: Boolean!
        $fetchRegionalInfo: Boolean!
        $zipCode: String!
        $userCountryCode: String = ""
        $localRecentHistoryItemIds: [String] = []
        $fetchUserRecentHistoryItem: Boolean = false
        $fetchTrackingGdprInfo: Boolean = true
        $priceBookName: String!
        $isGeoLocationPromoGroupSet: Boolean!
    ) {
        viewer {
            ...Header_viewer
        }
    }
`;

export const initialVariables = {
    hasUserId: false,
    hasTradeUserId: false,
    previewKey: '',
    variantId: '',
    fetchRegionalInfo: false,
    userId: '',
    userIds: [],
    isClient: false,
    zipCode: '',
    userCountryCode: '',
    priceBookName: 'DEFAULT',
    isGeoLocationPromoGroupSet: true, // prevent fetch by default
};
