/**
 * @generated SignedSource<<89f3cbaca9aacfcad9b7a09a460c3191>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type promoHelpers_trackGeoLocationPromos_viewer$data = {
  readonly promotionVariantControl?: ReadonlyArray<{
    readonly promoGroup: string | null;
    readonly promotionCode: string | null;
  } | null> | null;
  readonly " $fragmentType": "promoHelpers_trackGeoLocationPromos_viewer";
};
export type promoHelpers_trackGeoLocationPromos_viewer$key = {
  readonly " $data"?: promoHelpers_trackGeoLocationPromos_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"promoHelpers_trackGeoLocationPromos_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "promoHelpers_trackGeoLocationPromos_viewer"
};

(node as any).hash = "a7ba33d03816d05592fb70fef9306fdb";

export default node;
