import { Component, Suspense } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { bool, func, object } from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getBuyerId, getUserType, getPriceBookName } from 'dibs-cookie-jar';
import { isEmailOnly } from 'dibs-cookie-jar/exports/isEmailOnly';
import get from 'lodash.get';
import throttle from 'lodash.throttle';
import { Button } from 'dibs-elements/exports/Button';

import { TopBar } from '../TopBar/TopBar';
import BottomBar from '../BottomBar/BottomBar';
import Drawer from '../Drawer/Drawer';
import { MessageBannerLazy } from '../MessageBanner/MessageBannerLazy';

import { actionCreators } from '../../actions';
import * as userTracking from '../../utils/userTracking';
import {
    hasValidUserSessionGeoInfo,
    getUserSessionCountryCode,
    getUserZipCode,
} from 'dibs-regional-info/exports/regionalInfoHelpers';
import { getGeoLocationPromoGroupFromLocalStorage } from 'dibs-display-promo-code/exports/promoHelpers';
import { getLocalHistoryItemIds } from 'dibs-recent-history/exports/getLocalHistoryItemIds';
import { MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS } from 'dibs-recent-history/exports/constants';
import { TRADE } from '../../../exports/userTypeEnums';
import {
    getCurrencyPreference,
    setSessionCurrency,
    setLocalCurrency,
    getSessionCurrency,
} from '../../utils/preferredCurrency';
import { PriceIntlHandler } from '../PriceIntlHandler/PriceIntlHandler';
import { loginUserWithEmailLoginToken } from '../../helpers/loginUserWithEmailLoginTokenHelper';
import { getEmailLoginToken } from '../../helpers/getEmailLoginTokenHelper';

import styles from './Header-style.scss';

const MESSAGE_BANNER_HEIGHT = 42;
const SCROLL_THROTTLE_DURATION = 10;

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClient: false,
            hasError: false,
            forceSlideIn: false,
            isBannerOnScreen: props.showMarketingMessages && !props.disableStickyNav,
            hasMessageBanner: props.showMarketingMessages && !props.disableStickyNav,
            fragmentVariables: {
                hasUserId: false,
                hasTradeUserId: false,
                userId: '',
                userIds: [],
                fetchRegionalInfo: false,
                zipCode: '',
            },
            hasSentLoginMutation: false,
        };

        this.handleTopBarMouseOver = this.handleTopBarMouseOver.bind(this);
        this.handleTopBarMouseOut = this.handleTopBarMouseOut.bind(this);
        this.getHeaderParams = this.getHeaderParams.bind(this);
        this.setupCurrencyInfo = this.setupCurrencyInfo.bind(this);
        this.handleScroll = throttle(this.handleScroll.bind(this), SCROLL_THROTTLE_DURATION);
    }

    componentDidMount() {
        this.refetchViewer();

        const { hasMessageBanner } = this.state;
        if (hasMessageBanner) {
            this.handleScroll();
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    async componentDidUpdate(prevProps) {
        const { hasSentLoginMutation } = this.state;
        const {
            viewer,
            updateUserState,
            relay: { environment },
        } = this.props;

        const { user } = viewer || {};
        const { user: prevUser } = prevProps.viewer || {};

        const emailLoginToken = getEmailLoginToken();

        if (updateUserState && !prevProps.updateUserState) {
            this.refetchViewer();
        }
        if (user?.isVerifiedTrade && !prevUser) {
            this.props.updateUserTypeTrade();
        }
        if (user?.isVip && !prevUser) {
            this.props.updateUserTypeVip();
        }

        const userId = getBuyerId(document.cookie) || '';
        const shouldLoginUser = !hasSentLoginMutation && !userId && !!emailLoginToken;
        if (shouldLoginUser) {
            this.setState({ hasSentLoginMutation: true });
            const refetchViewer = () => {
                this.refetchViewer();
            };
            loginUserWithEmailLoginToken(environment, emailLoginToken, refetchViewer);
        }
    }

    componentDidCatch(error) {
        if (window.newrelic) {
            const newrelicError = new Error();
            newrelicError.message = `dibs-buyer-layout header error boundary triggered: ${error.message}`;
            newrelicError.stack = error.stack;

            window.newrelic.noticeError(newrelicError);
        }

        this.setState({ hasError: true });

        this.clearScrollListener();
    }

    componentWillUnmount() {
        this.clearScrollListener();
    }

    clearScrollListener() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const { isBannerOnScreen } = this.state;

        if (Drawer.getWindowScrollY() < MESSAGE_BANNER_HEIGHT) {
            if (!isBannerOnScreen) {
                this.setState({ isBannerOnScreen: true });
            }
        } else if (isBannerOnScreen) {
            this.setState({ isBannerOnScreen: false });
        }
    }

    getHeaderParams({ forceDisableStickyNav }) {
        const { disableStickyNav, showMarketingMessages, hideBottomBar } = this.props;
        const { isBannerOnScreen } = this.state;
        const disableSticky = disableStickyNav || forceDisableStickyNav;
        const hasBanner = showMarketingMessages;
        const isBannerOutOfScreen = hasBanner && !disableSticky && !isBannerOnScreen;

        return { disableSticky, hasBanner, isBannerOutOfScreen, hideBottomBar };
    }

    getClassnames({ forceDisableStickyNav, hasTopBarOnly }) {
        const { isBannerOutOfScreen, hasBanner, disableSticky, hideBottomBar } =
            this.getHeaderParams({
                forceDisableStickyNav,
            });

        return {
            wrapperClasses: classnames({
                [styles.wrapper]: true,
            }),
            headerClasses: classnames({
                [styles.header]: true,
                [styles.hasBanner]: hasBanner,
                [styles.isBannerOutOfScreen]: isBannerOutOfScreen,
                [styles.disableSticky]: disableSticky,
                [styles.hasTopBarOnly]: hasTopBarOnly,
                [styles.hideBottomBar]: hideBottomBar,
            }),
            shadowClasses: classnames({
                [styles.shadow]: true,
                [styles.disableSticky]: disableSticky,
                [styles.hasTopBarOnly]: hasTopBarOnly,
                [styles.isBannerOutOfScreen]: isBannerOutOfScreen,
                [styles.hideBottomBar]: hideBottomBar,
            }),
            bottomWrapperClasses: classnames({
                [styles.bottomWrapper]: true,
                [styles.disableSticky]: disableSticky,
            }),
        };
    }

    /**
     * The viewer should be fetched a max of (2) times:
     *
     * (1) On componentDidMount to fetch client-only fragments, and user fragments if already logged in
     * (2) On componentDidUpdate if user logs in
     */
    refetchViewer() {
        const { isUserTypeTrade } = this.props;
        const userId = getBuyerId(document.cookie) || '';
        const cookieUserType = getUserType(document.cookie) || '';
        const hasUserId = !!userId;
        const fetchRegionalInfo =
            (!hasUserId && !getSessionCurrency()) || !hasValidUserSessionGeoInfo();
        const isTradeUser = cookieUserType === TRADE || isUserTypeTrade;
        const hasTradeUserId = hasUserId && isTradeUser;
        this.props.setIsEmailOnly(isEmailOnly(document.cookie));
        const localRecentHistoryItemIds = getLocalHistoryItemIds({ isClient: true });

        const fragmentVariables = {
            ...this.state.fragmentVariables,
            hasUserId,
            hasTradeUserId,
            userId,
            userIds: [userId],
            isClient: true,
            fetchRegionalInfo,
            userCountryCode: getUserSessionCountryCode(),
            zipCode: getUserZipCode(),
            localRecentHistoryItemIds,
            fetchUserRecentHistoryItem:
                localRecentHistoryItemIds.length < MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS,
            priceBookName: getPriceBookName(document.cookie) || 'DEFAULT',
            isGeoLocationPromoGroupSet: !!getGeoLocationPromoGroupFromLocalStorage().length,
        };

        this.setState(
            {
                isClient: true,
                fragmentVariables,
            },
            () => {
                this.props.relay.refetch(
                    this.state.fragmentVariables,
                    null,
                    this.setupCurrencyInfo
                );
            }
        );
    }
    setupCurrencyInfo() {
        const { viewer } = this.props;

        const user = get(viewer, 'user');
        const userCurrency = get(user, 'preferences.currency');
        const regionalCurrency = get(viewer, 'regionalInfo[0].currency');

        if (!user && !getSessionCurrency()) {
            setSessionCurrency(regionalCurrency);
        } else if (userCurrency) {
            setLocalCurrency(userCurrency);
        }

        this.props.populateCurrency(getCurrencyPreference({ user, regionalCurrency }));
    }
    handleTopBarMouseOver() {
        if (!this.state.forceSlide) {
            this.setState({ forceSlideIn: true });
        }
    }

    handleTopBarMouseOut() {
        if (this.state.forceSlide) {
            this.setState({ forceSlideIn: false });
        }
    }

    render() {
        const {
            disableStickyNav,
            viewer,
            hideHeader,
            showMarketingMessages,
            setDrawerState,
            relay,
            initialTrackingFired,
            setInitialTrackingFired,
            isDrawerOpen,
            hideBottomBar,
        } = this.props;
        const user = get(viewer, 'user') || null;
        const regionalInfo = get(viewer, 'regionalInfo[0]') || null;
        const { isClient, fragmentVariables, forceSlideIn, hasError, hasMessageBanner } =
            this.state;
        const { hasUserId, userId, fetchRegionalInfo, hasTradeUserId } = fragmentVariables;
        const forceDisableStickyNav = hasError;
        const hasUser = !!user;
        const hasRootPendingRefetch =
            (!hasUser && hasUserId) || (fetchRegionalInfo && !get(viewer, 'regionalInfo'));
        const BottomWrapperTag = disableStickyNav || forceDisableStickyNav ? 'div' : Drawer;
        const bottomWrapperProps =
            disableStickyNav || forceDisableStickyNav
                ? {}
                : { onStateChange: setDrawerState, forceSlideIn };

        const { wrapperClasses, headerClasses, shadowClasses, bottomWrapperClasses } =
            this.getClassnames({ forceDisableStickyNav, hasTopBarOnly: hasError });

        const { isBannerOutOfScreen } = this.getHeaderParams({
            forceDisableStickyNav,
        });

        const isSticky =
            ((!hasMessageBanner || isBannerOutOfScreen) && !isDrawerOpen) || hideBottomBar;
        if (hasError) {
            return (
                <header className={wrapperClasses} hidden={hideHeader}>
                    <div className={headerClasses}>
                        <TopBar
                            hasError
                            hasRootPendingRefetch={hasRootPendingRefetch}
                            hasUser={hasUser}
                            hasUserId={hasUserId}
                            viewer={viewer}
                            isClient={isClient}
                            userId={userId}
                            user={user}
                            isSticky={isSticky}
                        />
                    </div>
                    <div className={shadowClasses} />
                </header>
            );
        }

        return (
            <header className={wrapperClasses} hidden={hideHeader}>
                <Button
                    className={styles.skipToContent}
                    type="secondaryAlt"
                    // use href to force anchor tag to render bc the button is for navigation and
                    // anchor is better semantically. prevent default to allow more control in
                    // navigation
                    href="#mainContent"
                    onClick={() => {
                        if (typeof document !== 'undefined') {
                            const mainContent = document.getElementById('mainContent');
                            const header = document.getElementById('js-header');
                            // main needs an offset bc when scrolling to, the target is aligned to top of the page, which is
                            // covered by the floating header. programmatically determine offset bc header has variable height.
                            const offset = header.offsetHeight + 'px';
                            mainContent.style.cssText = `margin-top: -${offset}; padding-top: ${offset};`;
                        }
                    }}
                >
                    <FormattedMessage
                        id="dbl.Header.SkipToContent"
                        defaultMessage="Skip to main content"
                    />
                </Button>
                <div className={headerClasses}>
                    {showMarketingMessages && (
                        <MessageBannerLazy
                            hasRootPendingRefetch={hasRootPendingRefetch}
                            relayEnvironment={relay.environment}
                            userId={userId}
                            hasUserId={hasUserId}
                            isClient={isClient}
                        />
                    )}

                    <TopBar
                        hasRootPendingRefetch={hasRootPendingRefetch}
                        hasUser={hasUser}
                        hasUserId={hasUserId}
                        viewer={viewer}
                        isClient={isClient}
                        userId={userId}
                        user={user}
                        regionalInfo={regionalInfo}
                        onMouseOver={this.handleTopBarMouseOver}
                        onMouseOut={this.handleTopBarMouseOut}
                        isSticky={isSticky}
                        hasTradeUserId={hasTradeUserId}
                    />
                    {hideBottomBar ? (
                        <div id="nav-bottom-marker" />
                    ) : (
                        <BottomWrapperTag className={bottomWrapperClasses} {...bottomWrapperProps}>
                            <BottomBar viewer={viewer} />
                            <div id="nav-bottom-marker" />
                        </BottomWrapperTag>
                    )}
                    {viewer.relayIsClient && (
                        <Suspense fallback={''}>
                            <userTracking.UserTrackingInit
                                viewer={viewer}
                                user={user}
                                environment={relay.environment}
                                initialTrackingFired={initialTrackingFired}
                                setInitialTrackingFired={setInitialTrackingFired}
                            />
                        </Suspense>
                    )}
                </div>
                <div className={shadowClasses} />
                <PriceIntlHandler />
            </header>
        );
    }
}

Header.propTypes = {
    disableStickyNav: bool,
    hideBottomBar: bool,
    hideHeader: bool,
    initialTrackingFired: bool,
    isClient: bool,
    isMobile: bool,
    isUserTypeTrade: bool,
    setInitialTrackingFired: func,
    showMarketingMessages: bool,
    relay: object,
    updateUserTypeTrade: func,
    updateUserTypeVip: func,
    populateCurrency: func,
    updateUserState: bool,
    viewer: object,
    setIsEmailOnly: func.isRequired,
    setDrawerState: func,
    isDrawerOpen: bool,
};

const mapStateToProps = ({ header, drawer }) => ({
    isMobile: header.isMobile,
    disableStickyNav: header.disableStickyNav,
    hideBottomBar: header.hideBottomBar,
    hideHeader: header.hideHeader,
    updateUserState: header.updateUserState,
    initialTrackingFired: header.initialTrackingFired,
    isUserTypeTrade: header.isUserTypeTrade,
    showMarketingMessages: header.showMarketingMessages,
    isDrawerOpen: drawer.isDrawerOpen,
});

const mapDispatchToProps = dispatch => ({
    updateUserTypeTrade: () => {
        dispatch(actionCreators.setUserTypeTrade(true));
    },
    updateUserTypeVip: () => {
        dispatch(actionCreators.setUserTypeVip(true));
    },
    setIsEmailOnly: emailOnly => {
        dispatch(actionCreators.setIsEmailOnly(emailOnly));
    },
    setInitialTrackingFired: () => {
        dispatch(actionCreators.setInitialTrackingFired());
    },
    populateCurrency: currency => dispatch(actionCreators.populateCurrency(currency)),
    setDrawerState: isDrawerOpen => {
        dispatch(actionCreators.setDrawerState(isDrawerOpen));
    },
});

Header = createRefetchContainer(
    connect(mapStateToProps, mapDispatchToProps)(Header),
    {
        viewer: graphql`
            fragment Header_viewer on Viewer {
                # component's isClient and relay's $isClient is out of sync
                # this var can be used to ensure we have issued a refetch and all the fragments are in place
                relayIsClient: __typename @include(if: $isClient)
                ...TopBar_viewer
                ...userTracking_viewer @defer @include(if: $isClient)
                ...BottomBar_viewer
                user(userId: $userId) @include(if: $hasUserId) {
                    ...userTracking_user @defer
                    ...TopBar_user
                    isVip
                    isVerifiedTrade
                    preferences {
                        currency
                    }
                }
                regionalInfo(userId: $userId, countryCode: $userCountryCode, zipCode: $zipCode)
                    @include(if: $fetchRegionalInfo) {
                    ...userTracking_regionalInfo @relay(mask: false)
                    currency
                }
            }
        `,
    },
    graphql`
        query HeaderRefetchQuery(
            $userId: String!
            $userIds: [String]!
            $isClient: Boolean!
            $hasUserId: Boolean!
            $hasTradeUserId: Boolean!
            $fetchRegionalInfo: Boolean!
            $previewKey: String = ""
            $variantId: String = ""
            $zipCode: String!
            $userCountryCode: String = ""
            $localRecentHistoryItemIds: [String] = []
            $fetchUserRecentHistoryItem: Boolean = false
            $fetchTrackingGdprInfo: Boolean = true
            $priceBookName: String!
            $isGeoLocationPromoGroupSet: Boolean!
        ) {
            viewer {
                ...Header_viewer
            }
        }
    `
);

export default Header;
