import { Children, cloneElement, type FC, isValidElement, type ReactNode } from 'react';
import { useFocusTrap, type Settings } from './useFocusTrap';

// TODO FI-1462 create new FocusTrap package, write docs, write test, write storybook

export const FocusTrap: FC<{ children: ReactNode } & Settings> = ({
    children,
    active,
    paused,
    options,
}) => {
    const child = Children.only(children);
    const barrierRef = useFocusTrap({ active, paused, options });
    if (isValidElement(child)) {
        // @ts-expect-error breaks after ts 4.9
        return cloneElement(child, { ref: barrierRef });
    } else {
        throw new Error('Focus trap child is not a valid ReactElement');
    }
};
