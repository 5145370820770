/**
 * @generated SignedSource<<6f3890cdec26fe3d668307e59d9a81df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type ImageVersionTypeName = "bgProcessed" | "datamatics" | "large" | "master" | "medium" | "oldMaster" | "original" | "placeholder" | "small" | "thumb" | "transparent" | "zoom" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CartNav_item$data = {
  readonly isSold: boolean | null;
  readonly pdpURL: string | null;
  readonly photos: ReadonlyArray<{
    readonly versions: ReadonlyArray<{
      readonly type: {
        readonly name: ImageVersionTypeName | null;
      } | null;
      readonly webPath: string | null;
    } | null> | null;
  } | null> | null;
  readonly pricing: {
    readonly netPrice: {
      readonly amount: number | null;
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
      readonly currency: Currency | null;
    } | null;
    readonly retailPrice: {
      readonly amount: number | null;
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
      readonly currency: Currency | null;
    } | null;
    readonly salePrice: {
      readonly amount: number | null;
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
      readonly currency: Currency | null;
    } | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "CartNav_item";
};
export type CartNav_item$key = {
  readonly " $data"?: CartNav_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartNav_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartNav_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdpURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemPhotoVersion",
          "kind": "LinkedField",
          "name": "versions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webPath",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageVersionType",
              "kind": "LinkedField",
              "name": "type",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userCountryCode",
          "variableName": "userCountryCode"
        }
      ],
      "concreteType": "ItemPricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "netPrice",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "salePrice",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialAmount",
          "kind": "LinkedField",
          "name": "retailPrice",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "f0a4a22979bfebd0d0e8358459f49cc9";

export default node;
