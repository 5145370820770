import { type FC, type MouseEvent } from 'react';
import { useIntl, defineMessages } from 'dibs-react-intl';

import { useSelector } from 'react-redux';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const {
    STEP_INTERACTION_GLOBAL_NAV_LOGOUT_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_ACC_INFORMATION_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_ORDERS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_CONVERSATIONS_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_NFT_COLLECTOR_CLICK,
} = stepInteractionConstants;
import { useFragment, graphql } from 'react-relay';
import authModalLoader from '../../utils/AuthModalLoader';
import {
    CREATE_PASSWORD_MY_ORDERS_FLOW,
    CREATE_PASSWORD_MY_CONVERSATIONS_FLOW,
    LOGIN,
    REQUEST_PASSWORD_EMAIL,
} from '../../authModal/authentication/authFlowConstants';
import { Link } from 'dibs-elements/exports/Link';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

import styles from './AccountNav-style.scss';

import { type AccountNavContent_user$key } from './__generated__/AccountNavContent_user.graphql';
import { type AccountNavContent_viewer$key } from './__generated__/AccountNavContent_viewer.graphql';
import { Badge } from 'dibs-elements/exports/Badge';

const trackingObj = {
    category: 'navigation',
    action: 'header navigation click',
    eventName: EVENT_NAVIGATION,
    interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
};

type ReduxStoreType = {
    header: {
        logoutUser: () => void;
        isEmailOnly: boolean;
    };
};

const messages = defineMessages({
    accountLabel: { id: 'dbl.Header.AccountNavContent.accountLabel', defaultMessage: 'Account' },
    logout: { id: 'dbl.Header.AccountNavContent.logout', defaultMessage: 'Log Out' },
    activePrivateOffers: {
        id: 'dbl.Header.AccountNavContent.activePrivateOffers',
        defaultMessage: 'Active Offers <sp>{unreadActiveOffers}</sp>',
    },
    myOrders: { id: 'dbl.Header.AccountNavContent.myOrders', defaultMessage: 'Orders' },
    accountInformation: {
        id: 'dbl.Header.AccountNavContent.accountInformation',
        defaultMessage: 'Account Information',
    },
    myConversations: {
        id: 'dbl.Header.AccountNavContent.myConversations',
        defaultMessage: 'Inbox',
    },
    nft: {
        id: 'dbl.Header.AccountNavContent.nftCollector',
        defaultMessage: 'NFT History',
    },
    nftProfile: {
        id: 'dbl.Header.AccountNavContent.nftProfile',
        defaultMessage: 'NFT Profile',
    },
});

type AccountNavDropdownContentProps = {
    user: AccountNavContent_user$key;
    viewer: AccountNavContent_viewer$key;
};

const AccountNavDropdownContent: FC<AccountNavDropdownContentProps> = ({
    user: userRef,
    viewer: viewerRef,
}) => {
    const user = useFragment(
        graphql`
            fragment AccountNavContent_user on User {
                profile {
                    email
                }
                conversationSummary {
                    unread
                    unreadActiveOffers
                }
                ethereumWalletAddress {
                    address
                }
            }
        `,
        userRef
    );
    const viewer = useFragment(
        graphql`
            fragment AccountNavContent_viewer on Viewer {
                activeOfferNavFF: featureFlag(feature: "activeOfferNav")
            }
        `,
        viewerRef
    );
    const hasEthereumWallet = !!user?.ethereumWalletAddress?.address;
    const totalUnreadConversations = user?.conversationSummary?.unread || 0;
    const unreadActiveOffers = user?.conversationSummary?.unreadActiveOffers || 0;
    const logoutUser = useSelector<ReduxStoreType, () => void>(({ header }) => header.logoutUser);
    const hasEmailToken = useSelector<ReduxStoreType, boolean>(({ header }) => header.isEmailOnly);
    const intl = useIntl();
    const onLogoutClick = (event: MouseEvent): void => {
        const label = 'user-nav|logout';
        trackEvent(
            {
                ...trackingObj,
                label,
                step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_LOGOUT_CLICK,
                trigger: label,
            },
            event
        );
        logoutUser();
    };

    const showAuthModal: (args: { flow: string; url: string }) => void = async ({ flow, url }) => {
        const email = user?.profile?.email;
        const res = await authModalLoader.show({
            flow,
            action: REQUEST_PASSWORD_EMAIL,
            email,
        });

        if (!res.err && res.modal === LOGIN) {
            window.location.href = handleLocaleUrl(url, GLOBAL_CLIENT_ONLY_LOCALE);
        }
    };
    return (
        <div className={styles.dropdownContent}>
            <div
                className={styles.dropdownLinkWrapper}
                data-tn="sub-nav-list-item-account-information"
            >
                <Link
                    className={styles.dropdownLink}
                    href="/my/account/"
                    ariaLabel={intl.formatMessage(messages.accountLabel)}
                    dataTn="sub-nav-list-item-link-account-information"
                    onClick={event => {
                        const label = 'user-nav|account information';
                        trackEvent(
                            {
                                ...trackingObj,
                                label,
                                step_interaction_name:
                                    STEP_INTERACTION_GLOBAL_NAV_ACC_INFORMATION_CLICK,
                                trigger: label,
                            },
                            event
                        );
                    }}
                >
                    {intl.formatMessage(messages.accountInformation)}
                </Link>
            </div>
            <div className={styles.dropdownLinkWrapper} data-tn="sub-nav-list-item-my-orders">
                <Link
                    className={styles.dropdownLink}
                    dataTn="sub-nav-list-item-link-my-orders"
                    onClick={async event => {
                        const url = '/orders/';
                        if (hasEmailToken) {
                            await showAuthModal({ flow: CREATE_PASSWORD_MY_ORDERS_FLOW, url });
                        } else {
                            const label = 'user-nav|my orders';
                            trackEvent(
                                {
                                    ...trackingObj,
                                    label,
                                    step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_ORDERS_CLICK,
                                    trigger: label,
                                },
                                event
                            );
                            window.location.href = handleLocaleUrl(url, GLOBAL_CLIENT_ONLY_LOCALE);
                        }
                    }}
                >
                    {intl.formatMessage(messages.myOrders)}
                </Link>
            </div>

            <div
                className={styles.dropdownLinkWrapper}
                data-tn="sub-nav-list-item-my-conversations"
            >
                <Link
                    className={styles.dropdownLink}
                    dataTn="sub-nav-list-item-link-my-conversations"
                    onClick={async event => {
                        const url = '/inbox/';
                        if (hasEmailToken) {
                            await showAuthModal({
                                flow: CREATE_PASSWORD_MY_CONVERSATIONS_FLOW,
                                url,
                            });
                        } else {
                            const label = 'user-nav|my conversations';
                            trackEvent(
                                {
                                    ...trackingObj,
                                    label,
                                    step_interaction_name:
                                        STEP_INTERACTION_GLOBAL_NAV_CONVERSATIONS_CLICK,
                                    trigger: label,
                                },
                                event
                            );
                            window.location.href = handleLocaleUrl(url, GLOBAL_CLIENT_ONLY_LOCALE);
                        }
                    }}
                >
                    {intl.formatMessage(messages.myConversations)}
                    {totalUnreadConversations ? (
                        <span className={styles.badgeContainer}>
                            <Badge type="alert"> {totalUnreadConversations}</Badge>
                        </span>
                    ) : null}
                </Link>
            </div>
            {viewer.activeOfferNavFF && (
                <div
                    className={styles.dropdownLinkWrapper}
                    data-tn="sub-nav-list-item-my-active-offers"
                >
                    <Link
                        className={styles.dropdownLink}
                        dataTn="sub-nav-list-item-link-my-active-offers"
                        onClick={event => {
                            const url = '/inbox/?filter=activePrivateOffer';
                            const label = 'user-nav|my offers';
                            trackEvent(
                                {
                                    ...trackingObj,
                                    label,
                                    step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_LOGOUT_CLICK,
                                    trigger: label,
                                },
                                event
                            );
                            window.location.href = handleLocaleUrl(url, GLOBAL_CLIENT_ONLY_LOCALE);
                        }}
                    >
                        {intl.formatMessage(messages.activePrivateOffers, {
                            unreadActiveOffers,
                            sp: text =>
                                unreadActiveOffers ? (
                                    <span
                                        key="offerBadgeContainer"
                                        className={styles.badgeContainer}
                                    >
                                        <Badge type="alert">{text}</Badge>
                                    </span>
                                ) : null,
                        })}
                    </Link>
                </div>
            )}

            {hasEthereumWallet && (
                <div
                    className={styles.dropdownLinkWrapper}
                    data-tn="sub-nav-list-item-nft-collector"
                >
                    <Link
                        className={styles.dropdownLink}
                        dataTn="sub-nav-list-item-link-nft-collector"
                        href="/nft/profile/"
                        onClick={async event => {
                            const label = 'user-nav|nft collector';
                            trackEvent(
                                {
                                    ...trackingObj,
                                    label,
                                    step_interaction_name:
                                        STEP_INTERACTION_GLOBAL_NAV_NFT_COLLECTOR_CLICK,
                                    trigger: label,
                                },
                                event
                            );
                        }}
                    >
                        {intl.formatMessage(messages.nftProfile)}
                    </Link>
                </div>
            )}

            <div className={styles.dropdownLinkWrapper} data-tn="sub-nav-list-item-logout">
                <Link
                    className={styles.dropdownLink}
                    dataTn="sub-nav-list-item-link-logout"
                    onClick={onLogoutClick}
                >
                    {intl.formatMessage(messages.logout)}
                </Link>
            </div>
        </div>
    );
};

export default AccountNavDropdownContent;
