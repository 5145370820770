import { type Environment, type GraphQLTaggedNode } from 'react-relay';
import { type MutationParameters } from 'relay-runtime';
import commitMutation from 'relay-commit-mutation-promise';

import { executeCaptcha } from './recaptcha';

// mimic `relay-commit-mutation-promise` api
export const commitMutationWithRecaptcha = async <
    MutationInputVariables,
    MutationType extends MutationParameters,
    MutationResponse extends MutationType['response']
>(
    environment: Environment,
    {
        mutation,
        mutationInputVariables,
        executeCaptchaConfig,
    }: {
        mutation: GraphQLTaggedNode;
        mutationInputVariables: MutationInputVariables;
        executeCaptchaConfig: Omit<
            Required<Parameters<typeof executeCaptcha>>[0],
            'relayEnvironment'
        >;
    }
): Promise<MutationResponse> => {
    return new Promise(async (resolve, reject) => {
        let attempt: MutationResponse;
        let captchaResults;
        try {
            captchaResults = await executeCaptcha({
                ...executeCaptchaConfig,
                relayEnvironment: environment,
            });
            // @ts-ignore
            // Type 'MutationType["response"]' is not assignable to type 'MutationResponse'.
            // 'MutationType["response"]' is assignable to the constraint of type 'MutationResponse', but 'MutationResponse' could be instantiated with a different subtype of constraint '{}'.
            // Type '{}' is not assignable to type 'MutationResponse'.
            //     '{}' is assignable to the constraint of type 'MutationResponse', but 'MutationResponse' could be instantiated with a different subtype of constraint '{}'.ts(2322)
            attempt = await commitMutation<MutationType>(environment, {
                mutation,
                variables: {
                    input: { ...mutationInputVariables, ...captchaResults },
                },
            });
            resolve(attempt);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            reject(err);
        }
    });
};
