import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const { STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_CLICK } = stepInteractionConstants;
import slugify from 'dibs-slugify/exports/slugify';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { ALWAYS_LINKABLE_LINK } from 'dibs-elements/exports/SeoLink';

import styles from './SiteNav-style.scss';

import {
    type SiteNavDropdownColumn_navigationTopLevel$key,
    type SiteNavDropdownColumn_navigationTopLevel$data,
} from './__generated__/SiteNavDropdownColumn_navigationTopLevel.graphql';
import { isSellerBrandingRemovalTestVariant } from '../../utils/abTest/sellerBrandingRemovalAbTestHelpers';
import { useClientState } from 'dibs-react-hooks/src/useClientState';

const navigationItemsFragment = graphql`
    fragment SiteNavDropdownColumn_navigationTopLevel on NavigationTopLevel @relay(plural: true) {
        name
        items {
            name
            linkData {
                ...SeoLink_linkData
                path
            }
        }
    }
`;

type Props = {
    navigationItems: SiteNavDropdownColumn_navigationTopLevel$key;
    trackingLabelPrefix: string;
};

type LinkData = NonNullable<
    NonNullable<NonNullable<SiteNavDropdownColumn_navigationTopLevel$data[number]>['items']>[number]
>['linkData'];

export const SiteNavDropdownColumn: FC<Props> = ({
    navigationItems: navigationItemsRef,
    trackingLabelPrefix,
}) => {
    const navigationItems = useFragment(navigationItemsFragment, navigationItemsRef);
    const hideSellerBranding = useClientState() && isSellerBrandingRemovalTestVariant();
    return (
        <div className={styles.dropdownCol}>
            {navigationItems.map((navigationItem, index) => (
                <div key={`${navigationItem.name}${index}`} className={styles.dropdownSection}>
                    {navigationItem.name ? (
                        <div className={styles.dropdownHeader}>{navigationItem.name}</div>
                    ) : null}
                    {(navigationItem.items || []).map((item, subItemIndex) => {
                        const { name, linkData } = item || {};
                        const label = `${trackingLabelPrefix}|${navigationItem.name}|${name}`;
                        if (hideSellerBranding && linkData?.path === '/seller-directory/') {
                            return null;
                        }

                        return (
                            <SeoLink
                                key={`${name}${subItemIndex}`}
                                dataTn={`global-nav-item-link-${slugify(label)}`}
                                className={styles.dropdownLink}
                                onClick={event => {
                                    trackEvent(
                                        {
                                            category: 'navigation',
                                            action: 'header navigation click',
                                            label: label.toLowerCase(),
                                            eventName: EVENT_NAVIGATION,
                                            interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                                            step_interaction_name:
                                                STEP_INTERACTION_GLOBAL_NAV_DROPDOWN_CLICK,
                                            trigger: label.toLowerCase(),
                                        },
                                        event
                                    );
                                }}
                                variant={ALWAYS_LINKABLE_LINK}
                                linkData={linkData as LinkData}
                            >
                                {name}
                            </SeoLink>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};
