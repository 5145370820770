import {
    useCallback,
    useRef,
    type FC,
    type ReactElement,
    type ChangeEvent,
    type FocusEvent,
    type KeyboardEvent,
    type MouseEvent,
    type FormEvent,
} from 'react';
import classnames from 'classnames';

import ClearIcon from 'dibs-icons/exports/legacy/Close';
import SearchIcon from 'dibs-icons/exports/legacy/MagnifyingGlass';

// components
import { SIZES } from '../Common/constants';
import { StyledButton } from '../Common/Button/StyledButton';
import { Input } from '../Input';
import { RotatingArrow } from '../RotatingArrow';
import { DropdownContent } from './DropdownContent';

// styles
import styles from './main.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { small, medium, large } = SIZES;

type PropsType = {
    dataTn: string;
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    size?: typeof small | typeof medium | typeof large;
    autoComplete?: boolean | string;
    disabled?: boolean;
    focusOnClear?: boolean;
    hasRightSearchButton?: boolean;
    isClearButtonHidden?: boolean;
    noBorder?: boolean;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onClear?: (e: MouseEvent<HTMLElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
    placeholder?: string;
    toggleDropdown?: () => void;
    dropdownIsOpen?: boolean;
    dropdownContent?: ReactElement;
    isHeaderSearch?: boolean;
    leftMargin?: typeof small;
};

const noop = (): void => {};

export const SearchBar: FC<PropsType> = props => {
    const {
        autoComplete = false,
        disabled = false,
        focusOnClear = false,
        hasRightSearchButton = true,
        noBorder = false,
        dropdownIsOpen = false,
        onBlur = noop,
        onClear = noop,
        onFocus = noop,
        onKeyDown = noop,
        dataTn,
        isClearButtonHidden,
        onChange,
        onSubmit,
        toggleDropdown,
        placeholder,
        size,
        value,
        defaultValue,
        dropdownContent,
        isHeaderSearch,
        leftMargin,
    } = props;

    const inputRef = useRef<HTMLInputElement | null>(null);

    const doChange = useCallback(
        (val: string) => {
            if (onChange) {
                onChange(val);
            }
        },
        [onChange]
    );

    const onInputChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
        doChange(e.target.value);
    };

    const handleOnBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            inputRef.current = e.target;
            if (onBlur) {
                onBlur(e);
            }
        },
        [onBlur, inputRef]
    );

    const handleOnClear = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            if (
                inputRef &&
                inputRef.current &&
                typeof inputRef.current.focus === 'function' &&
                focusOnClear
            ) {
                inputRef.current.focus();
            }
            doChange('');
            onClear(e);
        },
        [doChange, onClear, inputRef, focusOnClear]
    );

    const hideClearButton =
        typeof isClearButtonHidden !== 'undefined' ? isClearButtonHidden : !value;

    const showRightSearchButton = hasRightSearchButton && !toggleDropdown;
    const showPrimarySearchButton = showRightSearchButton && !hideClearButton;

    // default to medium size
    let buttonSize = styles.mediumButton;
    let searchButtonSize = styles.mediumSearch;
    if (size === small) {
        buttonSize = styles.smallButton;
        searchButtonSize = styles.smallSearch;
    } else if (size === large) {
        buttonSize = styles.largeButton;
        searchButtonSize = styles.largeSearch;
    }

    const searchButton = (
        <div tabIndex={-1} data-tn={`${dataTn}-search-button-wrapper`}>
            <StyledButton
                ariaLabel="Search"
                type="submit"
                dataTn={`${dataTn}-search-button`}
                className={classnames({
                    [styles.defaultSearch]: !size,
                    [searchButtonSize]: !!size,
                    [styles.noClick]: hideClearButton,
                    [styles.fullyTransparent]: hideClearButton || !showRightSearchButton,
                    [styles.whiteFill]: showPrimarySearchButton,
                    [styles.headerSearchButton]: isHeaderSearch,
                })}
                disabled={disabled}
                isPrimary={showPrimarySearchButton}
            >
                <SearchIcon />
            </StyledButton>
        </div>
    );

    const clearButton = (
        <div tabIndex={-1} data-tn={`${dataTn}-clear-wrapper`}>
            <StyledButton
                ariaLabel="Clear search terms"
                type="button"
                dataTn={`${dataTn}-clear`}
                className={classnames(styles.fullyTransparent, {
                    [styles.defaultButton]: !size,
                    [buttonSize]: !!size,
                    [styles.hideButton]: hideClearButton,
                    [styles.showButton]: !hideClearButton,
                    [styles.headerSearchClearButton]: isHeaderSearch,
                })}
                disabled={disabled}
                isPrimary={false}
                onClick={handleOnClear}
                tabIndex={hideClearButton ? -1 : undefined}
            >
                <ClearIcon />
            </StyledButton>
        </div>
    );

    const rightSearchVariation = (
        <Input
            // base input fields
            autoComplete={autoComplete}
            autoCorrect={false}
            dataTn={dataTn}
            disabled={disabled}
            noBorder={noBorder}
            onBlur={handleOnBlur}
            onChange={onInputChangeHandler}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            size={size}
            spellCheck={false}
            value={value}
            defaultValue={defaultValue}
            title="Search"
            ariaLabel="Search"
            leftMargin={leftMargin}
            isHeaderInput={isHeaderSearch}
            // right variation fields
            rightDecorator={
                <>
                    {clearButton}
                    {searchButton}
                </>
            }
            rightDecoratorClass={classnames(styles.decoratorWrapper, {
                [styles.shiftRight]: !noBorder,
            })}
        />
    );

    const leftSearchVariation = (
        <Input
            // base input fields
            autoComplete={autoComplete}
            autoCorrect={false}
            dataTn={dataTn}
            disabled={disabled}
            noBorder={noBorder}
            onBlur={handleOnBlur}
            onChange={onInputChangeHandler}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            size={size}
            spellCheck={false}
            value={value}
            defaultValue={defaultValue}
            title="Search"
            ariaLabel="Search"
            isHeaderInput={isHeaderSearch}
            // left variation fields
            leftDecorator={searchButton}
            leftDecoratorClass={styles.decoratorWrapper}
            leftMargin={leftMargin}
            rightDecorator={
                toggleDropdown ? (
                    <>
                        {clearButton}
                        <StyledButton
                            ariaLabel="Expand or collapse dropdown"
                            type="button"
                            className={classnames(styles.fullyTransparent, buttonSize)}
                            dataTn={`${dataTn}-dropdown-button`}
                            disabled={disabled}
                            isPrimary={false}
                            onClick={(event: MouseEvent<HTMLElement>) => {
                                // stop propagation so that useOutsideClick does not also fire
                                event.nativeEvent.stopImmediatePropagation();
                                toggleDropdown();
                            }}
                        >
                            <RotatingArrow direction={dropdownIsOpen ? 'up' : 'down'} />
                        </StyledButton>
                    </>
                ) : (
                    clearButton
                )
            }
            rightDecoratorClass={styles.decoratorWrapper}
        />
    );

    if (onSubmit) {
        return (
            <>
                <form data-tn={`${dataTn}-form`} onSubmit={onSubmit}>
                    <div className={styles.wrapper}>
                        {showRightSearchButton ? rightSearchVariation : leftSearchVariation}
                    </div>
                </form>
                {toggleDropdown && dropdownContent && (
                    <DropdownContent
                        dropdownContent={dropdownContent}
                        dropdownIsOpen={dropdownIsOpen}
                        toggleDropdown={toggleDropdown}
                    />
                )}
            </>
        );
    }

    const wrapperClassName = classnames(styles.wrapper, {
        [styles.openDropdown]: dropdownIsOpen && isHeaderSearch,
    });

    return (
        <>
            <div className={wrapperClassName}>
                {showRightSearchButton ? rightSearchVariation : leftSearchVariation}
            </div>
            {toggleDropdown && dropdownContent && (
                <DropdownContent
                    dropdownContent={dropdownContent}
                    dropdownIsOpen={dropdownIsOpen}
                    toggleDropdown={toggleDropdown}
                />
            )}
        </>
    );
};
