/**
 * @generated SignedSource<<3807552c5621209142fad3953df29931>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountNavTrigger_user$data = {
  readonly isVerifiedTrade: boolean | null;
  readonly profile: {
    readonly firstName: string | null;
  } | null;
  readonly tradeFirmRewards?: {
    readonly currentProgram: {
      readonly programInfo: {
        readonly displayName: string | null;
      } | null;
    } | null;
    readonly currentYearPoints: number | null;
  } | null;
  readonly " $fragmentType": "AccountNavTrigger_user";
};
export type AccountNavTrigger_user$key = {
  readonly " $data"?: AccountNavTrigger_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountNavTrigger_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasTradeUserId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountNavTrigger_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Profile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerifiedTrade",
      "storageKey": null
    },
    {
      "condition": "hasTradeUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TradeFirmRewards",
          "kind": "LinkedField",
          "name": "tradeFirmRewards",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentYearPoints",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ParentProgram",
              "kind": "LinkedField",
              "name": "currentProgram",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LoyaltyProgram",
                  "kind": "LinkedField",
                  "name": "programInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "76603bbb19c0cb9719c2592462d9c0f9";

export default node;
