import { graphql, readInlineData } from 'react-relay';
import { type promoHelpers_trackGeoLocationPromos_viewer$key } from './__generated__/promoHelpers_trackGeoLocationPromos_viewer.graphql';
import { trackNonStandardAbTest } from 'dibs-ab-tests/exports/clientAbTestV2';

type PromoGroup = {
    promoGroup: string;
    promotionCode: string | undefined;
};

type GeoLocationPromoList = PromoGroup[];
let geoPromoTracked = false;

export const getGeoLocationPromoGroupFromLocalStorage = (): GeoLocationPromoList => {
    let geoLocationPromos: GeoLocationPromoList = [];

    for (const [key, value] of Object.entries({ ...localStorage })) {
        if (key.includes('geoLocationPromoGroup:')) {
            const promotionCode = key.split(':').pop();
            geoLocationPromos = [
                ...geoLocationPromos,
                {
                    promoGroup: value,
                    promotionCode,
                },
            ];
        }
    }

    return geoLocationPromos;
};

const viewerFragment = graphql`
    fragment promoHelpers_trackGeoLocationPromos_viewer on Viewer @inline {
        promotionVariantControl(userId: $userId, listTypes: ["ABTEST_PROMOTIONS"])
            @skip(if: $isGeoLocationPromoGroupSet) {
            promoGroup
            promotionCode
        }
    }
`;

export const trackGeoLocationPromos = ({
    viewer: viewerRef,
}: {
    viewer: promoHelpers_trackGeoLocationPromos_viewer$key;
}): void => {
    let geoLocationPromos = getGeoLocationPromoGroupFromLocalStorage();
    const viewer = readInlineData(viewerFragment, viewerRef);
    if (!geoLocationPromos?.length) {
        viewer?.promotionVariantControl?.forEach(promoList => {
            const { promoGroup, promotionCode } = promoList || {};
            if (promoGroup && promotionCode) {
                localStorage.setItem(`geoLocationPromoGroup:${promotionCode}`, promoGroup);
                geoLocationPromos = [
                    ...geoLocationPromos,
                    {
                        promoGroup,
                        promotionCode,
                    },
                ];
            }
        });
    }

    if (!geoPromoTracked) {
        geoLocationPromos?.forEach(promo => {
            const promoGroupValue = promo?.promoGroup || '';
            trackNonStandardAbTest({
                variant: promoGroupValue.toLowerCase(),
                testName: 'BuyerApp-PromoGeo-May2024',
            });
        });
    }
    geoPromoTracked = true;
};
