/**
 * @generated SignedSource<<9fc6d1e4b6717b8a72f393cc8418ba4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteNavDropdownColumn_navigationTopLevel$data = ReadonlyArray<{
  readonly items: ReadonlyArray<{
    readonly linkData: {
      readonly path: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
    };
    readonly name: string | null;
  } | null> | null;
  readonly name: string | null;
  readonly " $fragmentType": "SiteNavDropdownColumn_navigationTopLevel";
}>;
export type SiteNavDropdownColumn_navigationTopLevel$key = ReadonlyArray<{
  readonly " $data"?: SiteNavDropdownColumn_navigationTopLevel$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteNavDropdownColumn_navigationTopLevel">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SiteNavDropdownColumn_navigationTopLevel",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NavigationItem",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LinkData",
          "kind": "LinkedField",
          "name": "linkData",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SeoLink_linkData"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "path",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavigationTopLevel",
  "abstractKey": null
};
})();

(node as any).hash = "93a540fc0d5407af9af9bc186ffad8de";

export default node;
