/**
 * @generated SignedSource<<41ec9aecdc5d6b8c5acfe58b0fc3a044>>
 * @relayHash 4666dc6eff5aa00ef6b160da893d7600
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/504.0.0-2025-04-01T10:32:06.638Z/NotificationsRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsRefetchQuery$variables = {
  buyerId: string;
  fetchNotifications: boolean;
  first?: number | null;
  isGeoLocationPromoGroupSet: boolean;
  userId: string;
};
export type NotificationsRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"Notifications_viewer">;
  };
};
export type NotificationsRefetchQuery = {
  response: NotificationsRefetchQuery$data;
  variables: NotificationsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "buyerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchNotifications"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isGeoLocationPromoGroupSet"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v5 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "fetchNotifications",
                "variableName": "fetchNotifications"
              },
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "Notifications_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotificationsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "isGeoLocationPromoGroupSet",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "listTypes",
                    "value": [
                      "ABTEST_PROMOTIONS"
                    ]
                  },
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "userId"
                  }
                ],
                "concreteType": "PromotionVariantControl",
                "kind": "LinkedField",
                "name": "promotionVariantControl",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "promoGroup",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "promotionCode",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchNotifications",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "conversations",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "buyerId",
                    "variableName": "buyerId"
                  },
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": "showUnreadOnly"
                  },
                  (v5/*: any*/)
                ],
                "concreteType": "ConversationsSolrConnection",
                "kind": "LinkedField",
                "name": "conversationsSolr",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationsFacetGroups",
                    "kind": "LinkedField",
                    "name": "facets",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConversationsFacets",
                        "kind": "LinkedField",
                        "name": "buyerTags",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unread",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationsSolrEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Conversation",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasUnreadMessageForMember",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "apiType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "inboxImage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "item",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latestMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latestMessageReceivedAt",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/504.0.0-2025-04-01T10:32:06.638Z/NotificationsRefetchQuery",
    "metadata": {},
    "name": "NotificationsRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "236d2a42eb86f0e78b3fd9c12b0a1d39";

export default node;
