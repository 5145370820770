/**
 * @generated SignedSource<<ac94fc18a9cbe64127cf8fb58e8a8b5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountNavContent_viewer$data = {
  readonly activeOfferNavFF: boolean | null;
  readonly " $fragmentType": "AccountNavContent_viewer";
};
export type AccountNavContent_viewer$key = {
  readonly " $data"?: AccountNavContent_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountNavContent_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountNavContent_viewer",
  "selections": [
    {
      "alias": "activeOfferNavFF",
      "args": [
        {
          "kind": "Literal",
          "name": "feature",
          "value": "activeOfferNav"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": "featureFlag(feature:\"activeOfferNav\")"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "ff282257a0c1fbc28fef130c6aa0c243";

export default node;
