import { type ReactNode } from 'react';

export async function loadCartNavDropdownContent(): Promise<ReactNode> {
    const asyncChunk = await import(
        /* webpackChunkName: "CartNavDropdownContent" */
        './CartNavDropdownContent'
    );
    const { default: CartNavDropdownContent } = asyncChunk;
    // Type 'ConnectedComponent<FC<Props>, Omit<Props, "isUserTypeTrade">>' is not assignable to type 'ReactNode'.ts(2322)
    // @ts-ignore
    return CartNavDropdownContent;
}
