import { commitMutation, graphql } from 'react-relay/legacy';
import { MAX_CART_ITEMS } from '../../actions/cartActions';

const mutation = graphql`
    mutation addCartItemMutation($input: ModifyPortfolioItemsInput!) {
        modifyPortfolioItems(input: $input) {
            portfolio {
                items {
                    totalResults
                }
            }
        }
    }
`;

export function addCartItemMutation({ environment, itemsIds, userId, onCompleted, onError }) {
    // `filter` array to ensure it doesn't contain falsy values
    itemsIds = (itemsIds || []).filter(Boolean);

    if (!itemsIds || !itemsIds.length) {
        return null;
    }

    itemsIds = itemsIds.slice(-MAX_CART_ITEMS);

    return commitMutation(environment, {
        mutation,
        variables: {
            input: {
                action: 'ADD',
                portfolioType: 'CART',
                itemsIds,
                userId,
            },
        },
        onCompleted,
        onError,
    });
}
