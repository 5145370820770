import { type RefObject } from 'react';

export function __INTERNAL_ONLY_HAS_ENOUGH_PARENT_HEIGHT__(
    containerNode: RefObject<HTMLDivElement | undefined>
): boolean {
    if (containerNode.current) {
        const parentNode: Element | null = containerNode.current.parentElement;
        return !!parentNode && parentNode.clientHeight > 45;
    }
    return false;
}
