/**
 * @generated SignedSource<<2755b1b6f61b8b1a1f70a4b977fd1082>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Likes_viewer$data = {
  readonly favoriteNotifications?: {
    readonly hasNewEvents: boolean | null;
    readonly id: string;
  } | null;
  readonly favoriteNotificationsTiles?: {
    readonly " $fragmentSpreads": FragmentRefs<"FavNotifications_favoriteNotificationsTiles">;
  } | null;
  readonly user?: {
    readonly " $fragmentSpreads": FragmentRefs<"FavNotifications_user">;
  } | null;
  readonly " $fragmentType": "Likes_viewer";
};
export type Likes_viewer$key = {
  readonly " $data"?: Likes_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Likes_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "createdAfter",
  "variableName": "createdAfter"
},
v2 = {
  "kind": "Variable",
  "name": "eventsLimit",
  "variableName": "eventsLimit"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "createdAfter"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "eventsLimit"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchNewEvents"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchNotifications"
    },
    {
      "defaultValue": 0,
      "kind": "LocalArgument",
      "name": "notificationItemsLimit"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Likes_viewer",
  "selections": [
    {
      "condition": "fetchNewEvents",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "FavoriteNotifications",
          "kind": "LinkedField",
          "name": "favoriteNotifications",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "kind": "ScalarField",
              "name": "hasNewEvents",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "fetchNotifications",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "favoriteNotificationsTiles",
          "args": (v0/*: any*/),
          "concreteType": "FavoriteNotifications",
          "kind": "LinkedField",
          "name": "favoriteNotifications",
          "plural": false,
          "selections": [
            {
              "args": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "Variable",
                  "name": "notificationItemsLimit",
                  "variableName": "notificationItemsLimit"
                }
              ],
              "kind": "FragmentSpread",
              "name": "FavNotifications_favoriteNotificationsTiles"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FavNotifications_user"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "a4530c230b8219b1ed58314ea9006fd4";

export default node;
